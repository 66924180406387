import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

export const dataOrderExternalState = atom({
  key: 'dataOrderExternalState',
  default: { rows: [], meta: {} },
})

export const fetchDataOrderExternal = async (params = '', cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`external-order?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const createOrderExternal = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('external-order', input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const dataOrderExternalOneState = atom({
  key: 'dataOrderExternalOneState',
  default: {},
})

export const fetchDataOrderExternalOne = async (orderId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`external-order/${orderId}`)
    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const addExternalBatchItem = async (orderId, input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`external-order/batch-add/${orderId}`, input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const publishExternal = async (orderId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`external-order/publish/${orderId}`)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const submitBillingExternal = async (orderId, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`billing/order-external/${orderId}`, payload)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const cancelBillingExternal = async (orderId, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`billing/order-external/${orderId}/cancel`, payload)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const dataRequestApprovalExternalState = atom({
  key: 'dataRequestApprovalExternalState',
  default: { rows: [], meta: {} },
})

export const fetchRequestApprovalExternal = async (param, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`external-order/approval?${param}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const dataHistoryApprovalExternalState = atom({
  key: 'dataHistoryApprovalExternalState',
  default: { rows: [], meta: {} },
})

export const fetchHistoryApprovalExternal = async (param, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`external-order/approval/history?${param}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const updateDataARExternalApprove = async (id, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`external-order/approval/approve/${id}`, payload)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const updateDataARExternalReject = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`external-order/approval/reject/${id}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// atach voucher
export const attachVoucherExternalOrder = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`external-order/voucher/attach`, input)
    if (data) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const detachVoucherExternalOrder = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`external-order/voucher/detach`, input)
    if (data) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const makeRevision = async (orderId, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`external-order/create-revision/${orderId}`, payload)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const setDoctorOrderExternal = async (id, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`external-order/set-doctor/${id}`, payload)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

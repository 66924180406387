import { CFormInput } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useDebounce from '../../hooks/useDebounce'

LiveSearch.propTypes = {
  setData: PropTypes.func,
  param: PropTypes.string,
  fetchData: PropTypes.func,
  placeholder: PropTypes.string,
  status: PropTypes.string,
  typeFetch: PropTypes.string,
  nested: PropTypes.bool,
  medicalId: PropTypes.string,
  fetchParam: PropTypes.string,
}

export default function LiveSearch({
  setData,
  param,
  fetchData,
  placeholder,
  status = null,
  typeFetch = null,
  nested = false,
  medicalId = null,
  fetchParam = null,
}) {
  const [search, setSearch] = useState('')
  const [isSearch, setIsearch] = useState(false)

  const searchValue = useDebounce(search)

  useEffect(() => {
    if (isSearch) {
      let params = ''
      if (typeFetch === 'dataRegistration') {
        params = `${param}=${searchValue}`
      } else {
        if (status) {
          if (status === 'new') {
            params = `${param}=${searchValue}&showOnlyLatest=true`
          } else {
            params = `${param}=${searchValue}&status=${status}`
          }
        } else {
          params = `${param}=${searchValue}`
        }
      }

      if (medicalId) {
        fetchData(medicalId, params, (data, err) => {
          if (typeFetch === 'dataRegistration' || nested) {
            setData(data.data)
          } else {
            setData(data)
          }
        })
      } else {
        if (fetchParam) {
          params = fetchParam + params
        }
        fetchData(params, (data, err) => {
          if (typeFetch === 'dataRegistration' || nested) {
            setData(data.data)
          } else {
            setData(data)
          }
        })
      }
    }
  }, [
    searchValue,
    setData,
    param,
    fetchData,
    isSearch,
    status,
    typeFetch,
    nested,
    medicalId,
    fetchParam,
  ])

  return (
    <>
      <CFormInput
        placeholder={placeholder}
        onChange={(e) => {
          setSearch(e.target.value)
          setIsearch(true)
        }}
      />
    </>
  )
}

import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { STORAGE_TOKEN_KEY } from '../api/constant'
import { fetchDataDetailMedicalRecord } from '../store'

export default function useFetchDataDetailMedicalRecord(setLoading, medicalRecordType, recordId) {
  const [dataDetailMedicalRecord, setDataDetailMedicalRecord] = useState({})

  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      if (recordId) {
        setLoading(true)
        fetchDataDetailMedicalRecord(medicalRecordType, recordId, (data, err) => {
          if (data) {
            setDataDetailMedicalRecord(data)
            setLoading(false)
            if (!data.length) {
              isApiSubscribed = false
            }
          } else {
            if (err.redirect) {
              localStorage.removeItem(STORAGE_TOKEN_KEY)
              history.push('/login')
              setLoading(false)
            }
          }
        })
      }
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [setLoading, recordId, medicalRecordType, history])

  return { dataDetailMedicalRecord, setDataDetailMedicalRecord }
}

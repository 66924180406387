import { CFormInput } from '@coreui/react'
import React from 'react'
import PropTypes from 'prop-types'

LiveSearchNew.propTypes = {
  setKeyword: PropTypes.func,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
}

export default function LiveSearchNew({ setKeyword, placeholder, onChange }) {
  return (
    <>
      <CFormInput
        placeholder={placeholder}
        onChange={(e) => {
          setKeyword(e.target.value)
          onChange()
        }}
      />
    </>
  )
}

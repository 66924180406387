import { useEffect } from 'react'
import { useHistory } from 'react-router'

import { STORAGE_TOKEN_KEY } from '../../api/constant'

export default function useFetchArrayWithCache(
  fetchData,
  params,
  data,
  setData,
  setLoading,
  id = '',
) {
  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      if (!data.length) {
        setLoading(true)
        if (id) {
          fetchData(id, params, (_data, err) => {
            if (_data) {
              setData(_data)
              setLoading(false)
              isApiSubscribed = false
            } else {
              if (err) {
                if (err.redirect) {
                  localStorage.removeItem(STORAGE_TOKEN_KEY)
                  history.push('/login')
                  setLoading(false)
                }
              }
            }
          })
        } else {
          fetchData(params, (_data, err) => {
            if (_data) {
              setData(_data)
              setLoading(false)
              isApiSubscribed = false
            } else {
              if (err) {
                if (err.redirect) {
                  localStorage.removeItem(STORAGE_TOKEN_KEY)
                  history.push('/login')
                  setLoading(false)
                }
              }
            }
          })
        }
      }
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [fetchData, history, params, setLoading, setData, data.length, id])
}

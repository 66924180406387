import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer'
import Proptypes from 'prop-types'
import Checkbox from '../components/Checkbox'

CheckboxMultiple.propTypes = {
  question: Proptypes.string,
  options: Proptypes.array,
}

export default function CheckboxMultiple({ question, options }) {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 10,
    },
    containerCheckbox: {
      display: 'flex',
      flexDirection: 'row',
    },
  })

  return (
    <View style={styles.container}>
      <Text style={{ width: 350 }}>{question}</Text>
      <View style={styles.containerCheckbox}>
        {options.map((option, index) => {
          return (
            <Checkbox
              key={index}
              text={option.label}
              isCheck={option.isCheck}
              style={{ marginLeft: 10 }}
            />
          )
        })}
      </View>
    </View>
  )
}

import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useRecoilState } from 'recoil'
import { dataMSQState, fetchDataMSQ } from '../../store'
import { STORAGE_TOKEN_KEY } from '../../api/constant'

export default function useFetchDataMSQ(medicalID, setLoading) {
  const [dataMSQ, setDataMSQ] = useRecoilState(dataMSQState)

  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      setLoading(true)
      fetchDataMSQ(medicalID, (data, err) => {
        if (data.status) {
          setDataMSQ(data.data)
          setLoading(false)
          isApiSubscribed = false
        } else {
          if (err) {
            if (err.redirect) {
              localStorage.removeItem(STORAGE_TOKEN_KEY)
              history.push('/login')
              setLoading(false)
            }
          }
        }
      })
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [history, medicalID, setDataMSQ, setLoading])

  return { dataMSQ, setDataMSQ }
}

import { atom } from 'recoil'
import { API } from '../api/helpers'

// fetch schedule
export const dataScheduleState = atom({
  key: 'dataScheduleState',
  default: [],
})
export const fetchDataSchedule = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`schedules?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' harap refresh website')
      }
    } else {
      alert(error.message)
    }
  }
}

import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import IntakePDF from './components/dashboard-dokter/forms/Intake/intakePDF/index'
// import StickerPDFTemplate from './components/dashboard-pharmacist/Prescription/StickerPDF/StickerPDFTemplate'
import { Loading } from './components/sharingComponents'
import './scss/style.scss'
import ProtectedRoute from './ProtectedRoute'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const EnterNewPassword = React.lazy(() => import('./views/pages/forgot-password/EnterNewPassword'))
const ForgotPassword = React.lazy(() => import('./views/pages/forgot-password/ForgotPassword'))
// const FeedbackPage = React.lazy(() => import('./views/pages/feedback/FeedbackPage'))
const NewFeedbackPage = React.lazy(() => import('./views/pages/feedback/NewFeedbackPage'))
const SurveyPage = React.lazy(() => import('./views/pages/survey'))

const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const VerificationFailed = React.lazy(() => import('./views/pages/register/VerificationFailed'))
const VerifyEmail = React.lazy(() => import('./views/pages/register/VerifyEmailPatient'))
const VerifyEmailPatientChange = React.lazy(() =>
  import('./views/pages/register/VerifyEmailPatientChange'),
)
const VerifyPassword = React.lazy(() => import('./views/pages/forgot-password/VerifyPassword'))

const ForcedLogout = React.lazy(() => import('./views/pages/logout/ForcedLogout'))

const Page403 = React.lazy(() => import('./views/pages/page403/Page403'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const OtpVerification = React.lazy(() => import('./views/pages/otpVerification/otpVerification'))
const ChangeNumberForOTP = React.lazy(() =>
  import('./views/pages/otpVerification/changeNumberForOTP'),
)
const ThankYouOTP = React.lazy(() => import('./views/pages/otpVerification/thankYouOTP'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={<Loading height="100vh" />}>
          <Switch>
            <Route
              exact
              path="/forced-logout"
              name="Logout"
              render={(props) => <ForcedLogout {...props} />}
            />

            <Route
              exact
              path="/403"
              name="403 Forbidden Access"
              render={(props) => <Page403 {...props} />}
            />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />

            <ProtectedRoute
              exact
              path="/login"
              name="Login Page"
              component={Login}
              // render={(props) => <Login {...props} />}
            />
            <ProtectedRoute
              exact
              path="/forgot-password"
              name="Forgot Password Page"
              component={ForgotPassword}
              // render={(props) => <ForgotPassword {...props} />}
            />

            {/* <ProtectedRoute
              exact
              path="/input-feedbacks"
              name="Feedback Page"
              component={FeedbackPage}
              // render={(props) => <FeedbackPage {...props} />}
            /> */}
            <Route
              exact
              path="/feedback-form"
              name="Feedback Page"
              render={(props) => <NewFeedbackPage {...props} />}
            />

            <ProtectedRoute
              exact
              path="/survey-test"
              name="Survey"
              component={SurveyPage}
              // render={(props) => <SurveyPage {...props} />}
            />

            <ProtectedRoute
              exact
              path="/otp-verification/:phone"
              name="OTP"
              component={OtpVerification}
              // render={(props) => <OtpVerification {...props} />}
            />

            <ProtectedRoute
              exact
              path="/change-number-otp"
              name="OTP"
              component={ChangeNumberForOTP}
              // render={(props) => <ChangeNumberForOTP {...props} />}
            />

            <ProtectedRoute
              exact
              path="/thankyou-otp"
              name="OTP"
              component={ThankYouOTP}
              // render={(props) => <ThankYouOTP {...props} />}
            />

            <ProtectedRoute
              exact
              path="/verify-password"
              name="Verify Password Page"
              component={VerifyPassword}
              // render={(props) => <VerifyPassword {...props} />}
            />

            <ProtectedRoute
              exact
              path="/verify-email"
              name="Verify Email Page"
              component={VerifyEmail}
              // render={(props) => <VerifyEmail {...props} />}
            />

            <ProtectedRoute
              exact
              path="/verify-email-changed"
              name="Verify Email Page"
              component={VerifyEmailPatientChange}
              // render={(props) => <VerifyEmailPatientChange {...props} />}
            />

            <ProtectedRoute
              exact
              path="/verification-failed"
              name="Verification Failed Page"
              component={VerificationFailed}
              // render={(props) => <VerificationFailed {...props} />}
            />

            <ProtectedRoute
              exact
              path="/new-password"
              name="New Password Page"
              component={EnterNewPassword}
              // render={(props) => <EnterNewPassword {...props} />}
            />

            {/* TEST PDF*/}
            <ProtectedRoute
              exact
              path="/test-pdf"
              name="TestPDF"
              component={IntakePDF}
              // render={(props) => <IntakePDF {...props} />}
            />
            {/* TEST PDF*/}

            <ProtectedRoute
              exact
              path="/register"
              name="Registration"
              // render={(props) => <Register {...props} />}
              component={Register}
            />
            <ProtectedRoute path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    )
  }
}

export default App

import React from 'react'
import PropTypes from 'prop-types'

import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilX } from '@coreui/icons'

const ModalCountAlert = ({
  visibleModalCountAlert,
  setVisibleModalCountAlert,
  deletingName,
  emptyName,
}) => {
  return (
    <>
      <CModal
        alignment="center"
        visible={visibleModalCountAlert}
        onClose={() => setVisibleModalCountAlert(false)}
        backdrop={'static'}
      >
        <CModalHeader>
          <CModalTitle>Alert !</CModalTitle>
        </CModalHeader>
        <CModalBody className="text-center">
          <CIcon
            icon={cilX}
            alt="Logo"
            style={{
              border: '4px solid red',
              borderRadius: '50%',
              padding: '10px',
              width: '6vw',
              height: '6vw',
              color: 'red',
              marginBottom: '16px',
            }}
          />
          <h2 style={{ marginBottom: '16px' }}>{deletingName} cannot be deleted</h2>
          <p>
            Please empty the {emptyName} first before deleting a {deletingName}.
          </p>
          <div style={{ height: '40px' }}></div>
        </CModalBody>
      </CModal>
    </>
  )
}

export default ModalCountAlert

ModalCountAlert.propTypes = {
  visibleModalCountAlert: PropTypes.bool,
  setVisibleModalCountAlert: PropTypes.func,
  emptyName: PropTypes.string,
  deletingName: PropTypes.string,
}

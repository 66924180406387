import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

export const dataLabTypesState = atom({
  key: 'dataLabTypesState',
  default: [],
})

export const fetchLabTypes = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('labtypes/list')
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const dataLabVendorState = atom({
  key: 'dataLabVendorState',
  default: [],
})

export const fetchLabVendor = async (locationType, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`labvendors/list?locationtype=${locationType}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const createLabSample = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`laboratory`, input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const dataLabSampleState = atom({
  key: 'dataLabSampleState',
  default: { rows: [], meta: {} },
})

export const fetchLabSample = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`laboratory?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchLabSampleById = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`laboratory/${id}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const updateLabSample = async (id, input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`laboratory/${id}`, input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const dataLabCourierState = atom({
  key: 'dataLabCourierState',
  default: [],
})

export const fetchLabCourier = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('courier/list')
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const createLabRemark = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('lab-tracking', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchLabRemark = async (id, params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`lab-tracking/list/${id}?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const dataLabRemarkState = atom({
  key: 'dataLabRemarkState',
  default: [],
})

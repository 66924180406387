// ProtectedRoute.js
import React, { useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { checkIP } from './store/preload'
import PropTypes from 'prop-types' // Impor PropTypes
import { Loading } from './components/sharingComponents'

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [isAllowed, setIsAllowed] = useState(null)

  useEffect(() => {
    checkIP((data) => {
      setIsAllowed(data.allowAccess)
    })
  }, [])

  if (isAllowed === null) {
    return <Loading /> // Anda bisa menampilkan loading indicator di sini
  }

  return (
    <Route
      {...rest}
      render={(props) => (isAllowed ? <Component {...props} /> : <Redirect to="/403" />)}
    />
  )
}
ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  // Tambahkan propTypes untuk properti lainnya yang digunakan, jika ada
}
export default ProtectedRoute

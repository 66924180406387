import { View, Text, StyleSheet } from '@react-pdf/renderer'
import React from 'react'
import Checkbox from '../components/Checkbox'
import Table from '../Table'
import PropTypes from 'prop-types'

Exercise.propTypes = {
  data: PropTypes.array,
}

export default function Exercise({ data }) {
  const styles = StyleSheet.create({
    title: {
      fontWeight: 'bold',
      color: '#AF924D',
      marginLeft: 5,
      marginBottom: 10,
    },
    question: {
      marginLeft: 5,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginTop: 10,
    },
    label: {
      display: 'flex',
      flexDirection: 'row',
      margin: 5,
    },
    underScore: {
      borderBottom: '1px solid black',
      flex: 1,
      padding: 0,
      paddingLeft: 5,
      marginLeft: 10,
    },
  })

  const headers = [
    {
      title: 'Activity',
      width: '25%',
      border: true,
    },
    {
      title: 'Type',
      width: '25%',
      border: true,
    },
    {
      title: '# of Times Per Week',
      width: '25%',
      border: true,
    },
    {
      title: 'Time/Duration (Minutes)',
      width: '25%',
      border: false,
    },
  ]

  const items = JSON.parse(data[0].answer).map((el) => {
    return {
      act: { data: el.activity ? el.activity : '-', width: '25%', border: true },
      type: { data: el.type ? el.type : '-', width: '25%', border: true },
      times: { data: el.numberPerWeek ? el.numberPerWeek : '-', width: '25%', border: true },
      time: { data: el.time ? el.time : '-', width: '25%', border: false },
    }
  })

  return (
    <>
      <Text style={{ ...styles.title, fontSize: 12, marginTop: 10, marginBottom: 10 }}>
        Current Exercise Program
      </Text>

      <Table headers={headers} items={items} />

      {data?.slice(1).map((el) => (
        <React.Fragment key={el.id}>
          {el.type === 'radio' && (
            <>
              <View style={{ ...styles.container, marginTop: 10 }}>
                <Text style={styles.question}>{el.question}</Text>
                {JSON.parse(el.answer).map((check) => (
                  <Checkbox
                    key={check.code}
                    isCheck={check.selected}
                    text={check.value}
                    style={{ margin: 5 }}
                  />
                ))}
              </View>
            </>
          )}

          {el.type === 'text' && (
            <View style={styles.label}>
              <Text>{el.question}</Text>
              <Text style={styles.underScore}>{el.answer}</Text>
            </View>
          )}
        </React.Fragment>
      ))}
    </>
  )
}

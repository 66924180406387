import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { fetchNotificationById } from '../../store'
import { STORAGE_TOKEN_KEY } from '../../api/constant'

export default function useFetchNotificationById(id, setLoading) {
  const history = useHistory()

  const [notif, setNotif] = useState({})

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      setLoading(true)
      fetchNotificationById(id, (data, err) => {
        isApiSubscribed = false

        if (err) {
          if (err.redirect) {
            localStorage.removeItem(STORAGE_TOKEN_KEY)
            history.push('/login')
          }
          isApiSubscribed = false
        } else {
          setNotif(data)
        }
        setLoading(false)
      })
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [history, id, setLoading])

  return { notif }
}

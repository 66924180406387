import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

export const dataOrderState = atom({
  key: 'dataOrderState',
  default: { rows: [], meta: {} },
})

export const dataHistoryOrderState = atom({
  key: 'dataHistoryOrderState',
  default: { data: [], meta: {} },
})

export const dataOrderShowOneState = atom({
  key: 'dataOrderShowOneState',
  default: {},
})

export const fetchDataOrderPagination = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`orders?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataOrder = async (orderId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`orders/${orderId}`)
    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const addItemOrder = async (orderId, input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`orders/batch-add/${orderId}`, input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const updateItemOrder = async (orderId, itemId, input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`orders/${orderId}/${itemId}`, input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const deleteItemOrder = async (orderId, itemId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete(`orders/${orderId}/${itemId}`)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const deleteItemExternalOrder = async (orderId, itemId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete(`external-order/${orderId}/${itemId}`)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const editItemExternalOrder = async (orderId, itemId, body, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`external-order/update-qty/${orderId}/${itemId}`, body)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const editItemOrder = async (orderId, itemId, body, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`orders/update-qty/${orderId}/${itemId}`, body)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const publishOrder = async (orderId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`orders/publish/${orderId}`)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const submitBilling = async (orderId, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`billing/order/${orderId}`, payload)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const cancelBilling = async (orderId, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`billing/order/${orderId}/cancel`, payload)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// attach voucher
export const attachVoucherOrder = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`orders/voucher/attach`, input)
    if (data) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// detach voucher
export const detachVoucherOrder = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`orders/voucher/detach`, input)
    if (data) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchHistoryOrder = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`orders/history/${id}`)
    if (data) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const makeRevision = async (orderId, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`orders/create-revision/${orderId}`, payload)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const setDoctorOrder = async (id, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`orders/set-doctor/${id}`, payload)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch feedbacks
export const dataFeedbackState = atom({
  key: 'dataFeedbackState',
  default: { rows: [], meta: {} },
})
export const fetchDataFeedback = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`feedback?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' harap refresh website')
      }
    } else {
      alert(error.message)
    }
  }
}

export const fetchRegisterFeedback = async (params, cb) => {
  try {
    const { request } = API(false)
    const { data } = await request.post(`feedback/get-register-data`, params)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
      }
    } else {
      alert(error.message)
    }
  }
}

export const sendFeedbackForm = async (params, cb) => {
  try {
    const { request } = API(false)
    const { data } = await request.post(`feedback/create-feedback-form`, params)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
      }
    } else {
      alert(error.message)
    }
  }
}

export const getTreatmentGroup = async (cb) => {
  try {
    const { request } = API(false)
    const { data } = await request.get('treatments/group')
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
      }
    } else {
      alert(error.message)
    }
  }
}
export const getSourceKnowUsList = async (cb) => {
  try {
    const { request } = API(false)
    const { data } = await request.get('feedback/source-know-us')
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
      }
    } else {
      alert(error.message)
    }
  }
}
export const getDoctorList = async (cb) => {
  try {
    const { request } = API(false)
    const { data } = await request.get('officers/doctor-list-review')
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
      }
    } else {
      alert(error.message)
    }
  }
}
export const getPracticionerList = async (cb) => {
  try {
    const { request } = API(false)
    const { data } = await request.get('officers/practitioner-list')
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
      }
    } else {
      alert(error.message)
    }
  }
}
export const getNurseList = async (cb) => {
  try {
    const { request } = API(false)
    const { data } = await request.get('officers/nurse-list')
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
      }
    } else {
      alert(error.message)
    }
  }
}
export const getBeauticianList = async (cb) => {
  try {
    const { request } = API(false)
    const { data } = await request.get('officers/beautician-list')
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
      }
    } else {
      alert(error.message)
    }
  }
}
export const getCroList = async (cb) => {
  try {
    const { request } = API(false)
    const { data } = await request.get('officers/cro-list')
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
      }
    } else {
      alert(error.message)
    }
  }
}

export const dataFeedbackFormState = atom({
  key: 'dataFeedbackFormState',
  default: { rows: [], meta: {} },
})
export const fetchDataFeedbackForm = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`feedback/feedback-form/list?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' harap refresh website')
      }
    } else {
      alert(error.message)
    }
  }
}

export const fetchDataFeedbackFormById = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`feedback/feedback-form/detail/${id}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' harap refresh website')
      }
    } else {
      alert(error.message)
    }
  }
}

export const createServingStaff = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`serving-staff/create`, params)
    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
      }
    } else {
      alert(error.message)
    }
  }
}

export const fetchDataServingStaff = async (regno, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`serving-staff/get-by-regno/${regno}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' harap refresh website')
      }
    } else {
      alert(error.message)
    }
  }
}

export const canEditServingStaff = async (regno, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`serving-staff/can-edit-staff/${regno}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' harap refresh website')
      }
    } else {
      alert(error.message)
    }
  }
}

export const exportData = async (payload, cb) => {
  try {
    const { request } = API(true)
    const response = await request.post(`feedback/feedback-form/export`, payload, {
      responseType: 'blob',
    })
    cb(response)
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' harap refresh website')
      }
    } else {
      alert(error.message)
    }
  }
}

export const getreportData = async (payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`feedback/feedback-form/report-data?${payload}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' harap refresh website')
      }
    } else {
      alert(error.message)
    }
  }
}

export const fetchChartFeedback = async (params, cb) => {
  // params = filter=year,month
  try {
    const { request } = API(true)
    const { data } = await request.get(`/feedback/feedback-form/chart-overall?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchChartFeedbackBySource = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/feedback/feedback-form/resource?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

import { View, Text } from '@react-pdf/renderer'
import React from 'react'
import QuestionWithUnderline from './QuestionWithUnderline'
import CheckboxMultiple from '../components/CheckboxMultiple'
import Table from '../Table'
import PropTypes from 'prop-types'

index.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
}

export default function index({ data: { CRT, NTN, ATB, STR } }) {
  function formatData(array, type) {
    const DEFAULT_MEDICATION = {
      med: { data: '', width: '30%', border: true },
      dos: { data: '', width: '20%', border: true },
      start: { data: '', width: '20%', border: true },
      reason: { data: '', width: '30%', border: false },
    }

    const DEFAULT_NUTRITION = {
      name: { data: '', width: '30%', border: true },
      dos: { data: '', width: '20%', border: true },
      start: { data: '', width: '20%', border: true },
      reason: { data: '', width: '30%', border: false },
    }

    const DEFAULT_ANTIBIOTIC = {
      question: { data: '', width: '30%', border: true },
      lt5: { data: '', width: '20%', border: true },
      mt5: { data: '', width: '20%', border: true },
      reason: { data: '', width: '30%', border: false },
    }

    let data = []
    let DEFAULT

    if (type === 'medication') {
      DEFAULT = DEFAULT_MEDICATION
      if (array !== null) {
        data = array.map((el) => {
          return {
            med: { data: el.medication, width: '30%', border: true },
            dos: { data: el.dosage, width: '20%', border: true },
            start: { data: el.start_date, width: '20%', border: true },
            reason: { data: el.reason, width: '30%', border: false },
          }
        })
      }
    } else if (type === 'nutrition') {
      DEFAULT = DEFAULT_NUTRITION
      if (array !== null) {
        data = array.map((el) => {
          return {
            name: { data: el.name, width: '30%', border: true },
            dos: { data: el.dosage, width: '20%', border: true },
            start: { data: el.start_date, width: '20%', border: true },
            reason: { data: el.reason, width: '30%', border: false },
          }
        })
      }
    } else if (type === 'antibiotics' || type === 'steroids') {
      DEFAULT = DEFAULT_ANTIBIOTIC
      if (array !== null) {
        data = array.map((el) => {
          return {
            question: { data: el.question, width: '30%', border: true },
            lt5: { data: el.lt5, width: '20%', border: true },
            mt5: { data: el.mt5, width: '20%', border: true },
            reason: { data: el.reason, width: '30%', border: false },
          }
        })
      }
    }

    if (data.length <= 5) {
      for (let i = 0; i <= 5 - (data.length - 1); i++) {
        data.push(DEFAULT)
      }
    }

    return data
  }

  // current medication
  const headMedication = [
    {
      title: 'Medication',
      width: '30%',
      border: true,
    },
    {
      title: 'Dosage',
      width: '20%',
      border: true,
    },
    {
      title: 'Start Date (mo/yr)',
      width: '20%',
      border: true,
    },
    {
      title: 'Reason for Use',
      width: '30%',
      border: false,
    },
  ]
  const itemMedication = formatData(JSON.parse(CRT[0].answer), 'medication')
  //  Nutrition
  const headNutrition = [
    {
      title: 'Name and Brand',
      width: '30%',
      border: true,
    },
    {
      title: 'Dosage',
      width: '20%',
      border: true,
    },
    {
      title: 'Start Date (mo/yr)',
      width: '20%',
      border: true,
    },
    {
      title: 'Reason for Use',
      width: '30%',
      border: false,
    },
  ]
  const itemNutrition = formatData(JSON.parse(NTN[0].answer), 'nutrition')

  //  antibiotics
  const headAntibiotic = [
    {
      title: '-',
      width: '30%',
      border: true,
    },
    {
      title: '< 5',
      width: '20%',
      border: true,
    },
    {
      title: '> 5',
      width: '20%',
      border: true,
    },
    {
      title: 'Reason for Use',
      width: '30%',
      border: false,
    },
  ]
  const itemAntibiotic = formatData(JSON.parse(ATB[0].answer), 'antibiotics')

  //  steroids
  const headSteroid = [
    {
      title: '-',
      width: '30%',
      border: true,
    },
    {
      title: '< 5',
      width: '20%',
      border: true,
    },
    {
      title: '> 5',
      width: '20%',
      border: true,
    },
    {
      title: 'Reason for Use',
      width: '30%',
      border: false,
    },
  ]
  const itemSteroid = formatData(JSON.parse(STR[0].answer), 'steroids')

  return (
    <View style={{ marginTop: 10 }}>
      <Text style={{ fontWeight: 'bold', color: '#AF924D', marginBottom: 10 }}>
        Medical History: Illnesses/Conditions
      </Text>
      <Text style={{ marginBottom: 10, fontWeight: 'bold', color: '#AF924D' }}>
        Current medications (include prescription and over-the-counter)
      </Text>
      <Table headers={headMedication} items={itemMedication} />

      <Text style={{ marginBottom: 10, marginTop: 10 }}>
        Nutritional supplements (vitamins/minerals/herbs etc.)
      </Text>
      <Table headers={headNutrition} items={itemNutrition} />

      <CheckboxMultiple
        question={NTN[1].question}
        options={[
          {
            label: 'Yes',
            isCheck: NTN[1].answer ? JSON.parse(NTN[1].answer)[0].selected : false,
          },
          {
            label: 'No',
            isCheck: NTN[1].answer ? JSON.parse(NTN[1].answer)[1].selected : false,
          },
        ]}
      />
      <QuestionWithUnderline question={'If yes,describe:'} answer={NTN[2].answer} />
      <View>
        <Text>Have you used any of these regularly or for a long time: {NTN[3].answer}</Text>
        <CheckboxMultiple
          question={'NSAIDs(Advil,Aleve,etc.),Motrin,Aspirin?'}
          options={[
            {
              label: 'Yes',
              isCheck: NTN[4].answer ? JSON.parse(NTN[4].answer)[0].selected : false,
            },
            {
              label: 'No',
              isCheck: NTN[4].answer ? JSON.parse(NTN[4].answer)[1].selected : false,
            },
          ]}
        />
        <CheckboxMultiple
          question={'Tylenol(acetaminophen)?'}
          options={[
            {
              label: 'Yes',
              isCheck: NTN[5].answer ? JSON.parse(NTN[5].answer)[0].selected : false,
            },
            {
              label: 'No',
              isCheck: NTN[5].answer ? JSON.parse(NTN[5].answer)[1].selected : false,
            },
          ]}
        />
        <CheckboxMultiple
          question={'Acid-blockingdrugs(Zantac,Prilosec,Nexium,etc.)?'}
          options={[
            {
              label: 'Yes',
              isCheck: NTN[5].answer ? JSON.parse(NTN[5].answer)[0].selected : false,
            },
            {
              label: 'No',
              isCheck: NTN[5].answer ? JSON.parse(NTN[5].answer)[1].selected : false,
            },
          ]}
        />
      </View>

      {/* antibiotic */}
      <Text style={{ marginBottom: 10, marginTop: 10 }}>
        How many times have you taken antibiotics?
      </Text>
      <Table headers={headAntibiotic} items={itemAntibiotic} />

      <CheckboxMultiple
        question={'Have you ever taken long term antibiotics?'}
        options={[
          {
            label: 'Yes',
            isCheck: ATB[1].answer ? JSON.parse(ATB[1].answer)[0].selected : false,
          },
          {
            label: 'No',
            isCheck: ATB[1].answer ? JSON.parse(ATB[1].answer)[1].selected : false,
          },
        ]}
      />
      <QuestionWithUnderline question={'If yes, explain:'} answer={ATB[2].answer} />
      <Text style={{ marginBottom: 10 }}>
        How often have you taken oral steroids (e.g., cortisone, prednisone, etc.)?
      </Text>
      <Table headers={headSteroid} items={itemSteroid} />
    </View>
  )
}

import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import Checkbox from './components/Checkbox'
import LabelAndText from './components/LabelAndText'

GeneralInformation.propTypes = {
  data: PropTypes.array,
}

export default function GeneralInformation({ data }) {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    title: {
      fontWeight: 'bold',
      color: '#AF924D',
      marginLeft: 5,
      marginBottom: 10,
    },
    label: {
      display: 'flex',
      flexDirection: 'row',
      margin: 5,
    },
    underScore: {
      borderBottom: '1px solid black',
      flex: 1,
      padding: 0,
      paddingLeft: 5,
      marginLeft: 10,
    },
  })

  return (
    <>
      <Text style={{ ...styles.title, fontSize: 14 }}>General Information</Text>
      <View style={styles.container}>
        <LabelAndText label="Name" text={data[0].answer ? JSON.parse(data[0].answer) : '-'} />
        <LabelAndText label="Age" text={data[1].answer ? JSON.parse(data[1].answer) : '-'} />
        <LabelAndText
          label="Today's Date"
          text={data[2].answer ? JSON.parse(data[2].answer) : '-'}
        />
        <LabelAndText
          label="Date Of Birth"
          text={data[3].answer ? JSON.parse(data[3].answer) : '-'}
        />
        <LabelAndText label="Email" text={data[4].answer ? JSON.parse(data[4].answer) : '-'} />
        <LabelAndText label="Address" text={data[5].answer ? JSON.parse(data[5].answer) : '-'} />
        <LabelAndText label="City" text={data[6].answer ? JSON.parse(data[6].answer) : '-'} />
        <LabelAndText label="State" text={data[7].answer ? JSON.parse(data[7].answer) : '-'} />
        <LabelAndText label="Zip" text={data[8].answer ? JSON.parse(data[8].answer) : '-'} />
        <LabelAndText
          label="Phone (Home)"
          text={data[9].answer ? JSON.parse(data[9].answer) : '-'}
        />
        <LabelAndText
          label="Phone (Cell)"
          text={data[10].answer ? JSON.parse(data[10].answer) : '-'}
        />
        <LabelAndText
          label="Phone (Work)"
          text={data[11].answer ? JSON.parse(data[11].answer) : '-'}
        />

        <View style={{ marginTop: 10, marginBottom: 10 }}>
          <Text style={{ marginBottom: 10, marginLeft: 5 }}>Genetic Background:</Text>
          <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {data[12].answer &&
              JSON.parse(data[12].answer).map((el) => (
                <React.Fragment key={el.code}>
                  {el.fillable ? (
                    <View style={{ display: 'flex', flexDirection: 'row', margin: 5 }}>
                      <Checkbox isCheck={el.checked} text={`${el.value}:`} />
                      <LabelAndText label="none" text={el.answer} />
                    </View>
                  ) : (
                    <Checkbox isCheck={el.checked} text={el.value} style={{ margin: 5 }} />
                  )}
                </React.Fragment>
              ))}
          </View>
        </View>
      </View>

      <View style={styles.label}>
        <Text>When, where and from whom did you last receive medical health or health care? :</Text>
        <Text style={styles.underScore}>{data[13].answer ? JSON.parse(data[13].answer) : '-'}</Text>
      </View>

      <View style={styles.container}>
        <View
          style={{
            width: '45%',
            display: 'flex',
            flexDirection: 'row',
            margin: 5,
            alignItems: 'baseline',
          }}
        >
          <Text>Emergency Contact :</Text>
          <Text style={styles.underScore}>
            {data[14].answer ? JSON.parse(data[14].answer) : '-'}
          </Text>
        </View>
        <LabelAndText
          label="Relationship"
          text={data[15].answer ? JSON.parse(data[15].answer) : '-'}
        />
        <LabelAndText
          label="Phone (Home)"
          text={data[16].answer ? JSON.parse(data[16].answer) : '-'}
        />
        <LabelAndText
          label="Phone (Cell)"
          text={data[17].answer ? JSON.parse(data[17].answer) : '-'}
        />
        <LabelAndText
          label="Phone (Work)"
          text={data[18].answer ? JSON.parse(data[18].answer) : '-'}
        />
      </View>

      <Text style={{ ...styles.title, fontSize: 12, marginTop: 10 }}>
        How did you hear about our practice ?
      </Text>
      <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {data[19].answer &&
          JSON.parse(data[19].answer).map((el) => (
            <React.Fragment key={el.code}>
              {el.fillable ? (
                <View style={{ display: 'flex', flexDirection: 'row', margin: 5 }}>
                  <Checkbox isCheck={el.checked} text={`${el.value}:`} />
                  <LabelAndText label="none" text={el.answer} />
                </View>
              ) : (
                <Checkbox isCheck={el.checked} text={el.value} style={{ margin: 5 }} />
              )}
            </React.Fragment>
          ))}
      </View>
    </>
  )
}

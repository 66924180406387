import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { STORAGE_TOKEN_KEY } from '../../api/constant'
import { fetchLabSampleById } from '../../store'

export default function useFetchLabSampleById(id, setData, setLoading) {
  const [sample, setSample] = useState({})
  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true

    if (isApiSubscribed) {
      if (id) {
        setLoading(true)

        fetchLabSampleById(id, (data, err) => {
          if (data) {
            setSample(data.lab)
            if (setData) {
              setData({
                status: data.lab.status,
                courier: data.lab.courier ? data.lab.courier.code : '',
                receiptNumber: data.lab.receiptNumber ? data.lab.receiptNumber : '',
              })
            }
            setLoading(false)
            isApiSubscribed = false
          } else {
            if (err.redirect) {
              localStorage.removeItem(STORAGE_TOKEN_KEY)
              history.push('/login')
              setLoading(false)
              isApiSubscribed = false
            }
          }
        })
      }
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [history, id, setLoading, setData])

  return { sample }
}

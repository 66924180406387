import React from 'react'
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import SeraphimLogo from '../../../../../assets/brand/logo_seraphim-01.png'
import { upperCaseFirstLetter } from '../../../../../helper/upperCaseFirstLetter'

Kop.propTypes = {
  data: PropTypes.object,
}

export default function Kop({ data }) {
  const styles = StyleSheet.create({
    kop: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 28.7,
    },
    image: {
      width: 100,
      height: 100,
    },
    kopTitle: {
      border: '1px solid white',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      padding: 10,
      marginLeft: 30,
      width: 500,
      backgroundColor: '#AF924D',
    },
    titleColor: {
      color: 'white',
      fontWeight: 'bold',
      fontSize: 20,
    },
  })
  return (
    <View style={styles.kop}>
      <Image style={styles.image} src={SeraphimLogo} />
      <View style={styles.kopTitle}>
        <Text style={styles.titleColor}>
          {upperCaseFirstLetter(data?.type)} Intake Questionnaire
        </Text>
      </View>
    </View>
  )
}

import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

export const dataOrderLabState = atom({
  key: 'dataOrderLabState',
  default: { rows: [], meta: {} },
})

export const dataResultLabState = atom({
  key: 'dataResultLabState',
  default: { rows: [], meta: {} },
})

export const dataInstructionBTCLabState = atom({
  key: 'dataInstructionBTCLabState',
  default: { rows: [], meta: {} },
})

export const fetchDataOrderLab = async (params = '', cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`order-lab?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
export const fetchDataPatientOrderLab = async (medicalId, params = '', cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`order-lab/patient/${medicalId}?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchOneOrderLab = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`order-lab/${id}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// answer request
export const AnswerOrderLab = async (id, status, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`/order-lab/${id}/${status}`, payload)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const updateVendorLab = async (id, body, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`order-lab/${id}/update-vendor`, body)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const publishVendorLab = async (id, body, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`order-lab/${id}/publish-vendor`, body)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const dataSampleTypeState = atom({
  key: 'dataSampleTypeState',
  default: [],
})

export const fetchDataSampleType = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`order-lab/sample-types`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// create order lab
export const createOrderLab = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('order-lab', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const dataOrderLabPaidState = atom({
  key: 'dataOrderLabPaidState',
  default: { rows: [], meta: {} },
})

export const fetchDataOrderLabPaid = async (params = '', cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`order-lab/paid?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// upload result
export const uploadResultOrderLab = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('order-lab/paid/uploadresult', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataPatientResultLab = async (medicalId, params = '', cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`order-lab/lab-result/${medicalId}?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const syncDataPatientResultLab = async (medicalId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`order-lab/lab-result/${medicalId}/sync-lab-bethsaida`)
    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataLabResultDetail = async (medicalId, id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`order-lab/lab-result/${medicalId}/detail/${id}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const getLabTestList = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`treatments/orderlab/list`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'

TableCheckbox.propTypes = {
  headers: PropTypes.array,
  items: PropTypes.array,
}

export default function TableCheckbox({ headers, items }) {
  const borderColor = '#d9da55'
  const styles = StyleSheet.create({
    tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      borderWidth: 1,
      borderColor: '#d9da55',
    },
    titleContainer: {
      flexDirection: 'row',
      borderBottomColor: '#d9da55',
      backgroundColor: '#afbc21',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 24,
      textAlign: 'center',
      fontStyle: 'bold',
      flexGrow: 1,
    },
    title: {
      color: '#015287',
      fontWeight: 'bold',
      height: '100%',
      paddingTop: 8,
    },
    borderRight: {
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    rowContainer: {
      flexDirection: 'row',
      borderBottomColor: '#d9da55',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 24,
      fontStyle: 'bold',
    },
    row: {
      textAlign: 'center',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
      color: '#015287',
      backgroundColor: '#f9f9ea',
      height: '100%',
    },
    box: {
      width: 10,
      height: 10,
      border: '1px solid black',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 auto',
      fontSize: 7,
    },
  })

  const border = (data) => (data.border ? styles.borderRight : null)

  const CheckboxNoText = (isCheck) => (
    <View style={styles.box}>{isCheck.isCheck ? <Text>x</Text> : null}</View>
  )

  return (
    <View style={styles.tableContainer}>
      {/* headers */}
      <View style={styles.titleContainer}>
        {headers.map((head, i) => (
          <Text key={i} style={{ ...styles.title, ...border(head), width: head.width }}>
            {head.title}
          </Text>
        ))}
      </View>
      {/* rows */}
      {items.map((data, j) => {
        const keys = Object.keys(data)
        return (
          <View style={styles.rowContainer} key={j}>
            {keys.map((el, k) => (
              <View key={k} style={{ ...styles.row, width: data[el].width, ...border(data[el]) }}>
                {data[el].data.type === 'text' ? (
                  <Text>{data[el].data.text}</Text>
                ) : data[el].data.type === 'checkbox' ? (
                  <CheckboxNoText isCheck={data[el].data.check} />
                ) : null}
              </View>
            ))}
          </View>
        )
      })}
    </View>
  )
}

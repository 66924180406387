import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { dataLabVendorState, fetchLabVendor } from '../../store'
import { STORAGE_TOKEN_KEY } from '../../api/constant'
import { useRecoilState } from 'recoil'

export default function useFetchLabVendors(locationType, setLoading) {
  const history = useHistory()

  const [labVendors, setLabVendors] = useRecoilState(dataLabVendorState)

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      setLoading(true)
      fetchLabVendor(locationType, (data, err) => {
        isApiSubscribed = false

        if (err) {
          if (err.redirect) {
            localStorage.removeItem(STORAGE_TOKEN_KEY)
            history.push('/login')
          }
          isApiSubscribed = false
        } else {
          setLabVendors(data)
        }
        setLoading(false)
      })
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [history, setLoading, setLabVendors, locationType])

  return { labVendors }
}

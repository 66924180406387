import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch

export const dataMSQFormAllState = atom({
  key: 'dataMSQFormAllState',
  default: { rows: [], meta: {} },
})

export const dataShowMsqFormState = atom({
  key: 'dataShowMsqFormState',
  default: {},
})

export const fetchMSQFormByPatient = async (medicalId, param, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`msq-form/patient/${medicalId}?${param}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchMSQFormOne = async (formCode, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`msq-form/${formCode}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const assignFormToPatient = async (body, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`msq-form`, body)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const remindPatient = async (body, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`msq-form/remind-patient`, body)
    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const uploadGeneratePdf = async (body, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`msq-form/upload-report`, body)
    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

import React from 'react'
import { Text, StyleSheet, View } from '@react-pdf/renderer'
import Proptypes from 'prop-types'

QuestionWithUnderline.propTypes = {
  question: Proptypes.string,
  answer: Proptypes.string,
}

export default function QuestionWithUnderline({ question, answer }) {
  const styles = StyleSheet.create({
    label: {
      display: 'flex',
      flexDirection: 'row',
      margin: 5,
    },
    name: {
      width: 250,
    },
    underScore: {
      borderBottom: '1px solid black',
      flex: 1,
      padding: 0,
      paddingLeft: 5,
      marginLeft: 10,
    },
  })
  return (
    <View style={styles.label}>
      {question !== 'none' && <Text style={styles.name}>{question} :</Text>}
      <Text style={styles.underScore}>{answer}</Text>
    </View>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import { CSpinner } from '@coreui/react'

const Loading = ({ height = '60vh' }) => {
  Loading.propTypes = {
    height: PropTypes.string,
  }

  return (
    <div className="w-100 loading" style={{ height: height }}>
      <CSpinner color="primary" />
    </div>
  )
}

export default Loading

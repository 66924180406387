import { useEffect } from 'react'
import { useHistory } from 'react-router'

import { STORAGE_TOKEN_KEY } from '../api/constant'
import { fetchMSQFormOne } from '../store'

export default function useFetchMsqForm(formCode, data, setData) {
  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed && formCode !== null) {
      fetchMSQFormOne(formCode, (_data, err) => {
        if (_data) {
          setData(_data)
          isApiSubscribed = false
        } else {
          if (err) {
            if (err.redirect) {
              localStorage.removeItem(STORAGE_TOKEN_KEY)
              history.push('/login')
            }
          }
        }
      })
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, formCode])

  return { data, setData }
}

import React from 'react'
import { Text, StyleSheet } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import Table from '../Table'

CurrentHealthConcern.propTypes = {
  data: PropTypes.array,
}

export default function CurrentHealthConcern({ data }) {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    title: {
      fontWeight: 'bold',
      color: '#AF924D',
      marginLeft: 5,
      marginBottom: 10,
    },
  })

  const DEFAULT = {
    problem: { data: '-', width: '35%', border: true },
    Mild: { data: '-', width: '5%', border: true },
    Moderate: { data: '-', width: '5%', border: true },
    Severe: { data: '-', width: '5%', border: true },
    priorTreatment: { data: '-', width: '35%', border: true },
    Excellent: { data: '-', width: '5%', border: true },
    Good: { data: '-', width: '5%', border: true },
    Fair: { data: '-', width: '5%', border: false },
  }

  const items = data.map((el) => {
    const obj = {
      problem: { data: '-', width: '35%', border: true },
      Mild: { data: '-', width: '5%', border: true },
      Moderate: { data: '-', width: '5%', border: true },
      Severe: { data: '-', width: '5%', border: true },
      priorTreatment: { data: '-', width: '35%', border: true },
      Excellent: { data: '-', width: '5%', border: true },
      Good: { data: '-', width: '5%', border: true },
      Fair: { data: '-', width: '5%', border: false },
    }
    for (const key in el) {
      if (obj[key]) {
        obj[key].data = el[key]
      }
      if (obj[el[key]]) {
        obj[el[key]].data = 'x'
      }
    }
    return obj
  })

  if (items.length <= 5) {
    const length = 5 - items.length
    for (let i = 0; i < length; i++) {
      items.push(DEFAULT)
    }
  }

  const headers = [
    {
      title: 'Describe Problem Severity',
      width: '35%',
      border: true,
    },
    {
      title: 'Mi',
      width: '5%',
      border: true,
    },
    {
      title: 'Mo',
      width: '5%',
      border: true,
    },
    {
      title: 'S',
      width: '5%',
      border: true,
    },
    {
      title: 'Prior Treatment/Approach Success',
      width: '35%',
      border: true,
    },
    {
      title: 'E',
      width: '5%',
      border: true,
    },
    {
      title: 'G',
      width: '5%',
      border: true,
    },
    {
      title: 'F',
      width: '5%',
      border: true,
    },
  ]

  return (
    <>
      <Text style={{ ...styles.title, fontSize: 14, marginTop: 10 }}>Current Health Concerns</Text>
      <Text style={{ ...styles.title, fontSize: 12, marginBottom: 10 }}>
        Please rank current and ongoing health concerns in order of priority
      </Text>
      <Text style={{ ...styles.title, fontSize: 8, marginBottom: 10, color: 'black' }}>
        Notes: Mi = Mild, Mo = Moderate, S = Severe, E = Excellent, G = Good, F = Fair
      </Text>

      <Table headers={headers} items={items} />
    </>
  )
}

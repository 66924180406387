import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch patients

export const dataFMTreatmentDiagnosticLab = atom({
  key: 'dataFMTreatmentDiagnosticLab',
  default: { rows: [], meta: {} },
})

export const dataFMTreatmentDiagnosticLabAttachment = atom({
  key: 'dataFMTreatmentDiagnosticLabAttachment',
  default: { rows: [], meta: {} },
})
export const dataFMTreatmentDiagnosticRadiology = atom({
  key: 'dataFMTreatmentDiagnosticRadiology',
  default: { rows: [], meta: {} },
})

export const dataFMTreatmentDiagnosticRadiologyAttachment = atom({
  key: 'dataFMTreatmentDiagnosticRadiologyAttachment',
  default: { rows: [], meta: {} },
})

export const dataFMTreatmentSleepRelax = atom({
  key: 'dataFMTreatmentSleepRelax',
  default: { rows: [], meta: {} },
})

export const dataFMTreatmentSleepRelaxAttachment = atom({
  key: 'dataFMTreatmentSleepRelaxAttachment',
  default: { rows: [], meta: {} },
})

export const dataFMTreatmentExcerciseMovement = atom({
  key: 'dataFMTreatmentExcerciseMovement',
  default: { rows: [], meta: {} },
})

export const dataFMTreatmentExcerciseMovementAttachment = atom({
  key: 'dataFMTreatmentExcerciseMovementAttachment',
  default: { rows: [], meta: {} },
})

export const dataFMTreatmentNutrition = atom({
  key: 'dataFMTreatmentNutrition',
  default: { rows: [], meta: {} },
})

export const dataFMTreatmentNutritionAttachment = atom({
  key: 'dataFMTreatmentNutritionAttachment',
  default: { rows: [], meta: {} },
})

export const dataFMTreatmentStress = atom({
  key: 'dataFMTreatmentStress',
  default: { rows: [], meta: {} },
})

export const dataFMTreatmentStressAttachment = atom({
  key: 'dataFMTreatmentStressAttachment',
  default: { rows: [], meta: {} },
})

export const dataFMTreatmentRelationship = atom({
  key: 'dataFMTreatmentRelationship',
  default: { rows: [], meta: {} },
})

export const dataFMTreatmentRelationshipAttachment = atom({
  key: 'dataFMTreatmentRelationshipAttachment',
  default: { rows: [], meta: {} },
})

export const dataFMTreatmentSupplement = atom({
  key: 'dataFMTreatmentSupplement',
  default: { rows: [], meta: {} },
})

export const dataFMTreatmentSupplementAttachment = atom({
  key: 'dataFMTreatmentSupplementAttachment',
  default: { rows: [], meta: {} },
})

export const dataFMTreatmentMedications = atom({
  key: 'dataFMTreatmentMedications',
  default: { rows: [], meta: {} },
})

export const dataFMTreatmentMedicationsAttachment = atom({
  key: 'dataFMTreatmentMedicationsAttachment',
  default: { rows: [], meta: {} },
})

export const dataMedicineState = atom({
  key: 'dataMedicineState',
  default: [],
})

export const dataFrequencyListState = atom({
  key: 'dataFrequencyListState',
  default: [],
})

export const dataRouteListState = atom({
  key: 'dataRouteListState',
  default: [],
})

export const dataInputArraySupplementOrMedication = atom({
  key: 'dataInputArraySupplementOrMedication',
  default: [],
})

export const fetchFMTreatmentRecord = async (medicalRecordType, params, medicalId, cb) => {
  try {
    const { request } = API(true)
    // page=1&size=10&orderBy=createdAt&orderDir=desc&search
    const { data } = await request.get(`fmtforms/${medicalRecordType}/${medicalId}?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchFMTreatmentDetailRecord = async (medicalRecordType, params, supplementId, cb) => {
  try {
    const { request } = API(true)
    // page=1&size=10&orderBy=createdAt&orderDir=desc&search
    const { data } = await request.get(
      `fmtforms/${medicalRecordType}/detail/${supplementId}?${params}`,
    )

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// create patients
export const createFMTreatmentRecord = async (medicalRecordType, input, medicalId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`fmtforms/${medicalRecordType}/${medicalId}`, input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataMedicine = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/products/medicines/list`)
    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataFrequency = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/signatures/list`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataRoute = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/routes/list`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

import React from 'react'
import { Text, StyleSheet, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import PatientBirth from './PatientBirth'
import DentalHistory from './DentalHistory'
import Evironmental from './Environmental'
import MensHistory from './MensHistory'
import FamilyHistory from './FamilyHistory'
import { upperCaseFirstLetter } from '../../../../../../helper/upperCaseFirstLetter'

History.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
}

export default function History({ data: { PBC, DNL, EDH, MNH, WNH, FMH }, type }) {
  const styles = StyleSheet.create({
    title: {
      fontWeight: 'bold',
      color: '#AF924D',
      marginLeft: 5,
      marginBottom: 10,
      marginTop: 10,
    },
  })

  return (
    <>
      <Text style={{ ...styles.title, fontSize: 14, marginTop: 20 }}>History</Text>

      <Text style={{ ...styles.title, fontSize: 12 }}>Patient&apos;s Birth/Childhood History</Text>
      <PatientBirth data={PBC} />
      <Text style={{ ...styles.title, fontSize: 12 }}>Dental History</Text>
      <DentalHistory data={DNL} />
      <Text style={{ ...styles.title, fontSize: 12 }}>Evironmental/Detoxification History</Text>
      <Evironmental data={EDH} />
      <Text style={{ ...styles.title, fontSize: 12, marginTop: 30 }}>
        {upperCaseFirstLetter(type)}&apos;s History
      </Text>
      {type === 'male' ? <MensHistory data={MNH} /> : <MensHistory data={WNH} />}

      {type === 'female' && <View style={{ marginTop: 20 }}></View>}
      <Text style={{ ...styles.title, fontSize: 12 }}>Family History:</Text>
      <FamilyHistory data={FMH} />
    </>
  )
}

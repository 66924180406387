import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useRecoilState } from 'recoil'
import { dataMedicineState, fetchDataMedicine } from '../../store'
import { STORAGE_TOKEN_KEY } from '../../api/constant'

export default function useFetchDataMedicine(setLoading) {
  const [dataMedicine, setDataMedicine] = useRecoilState(dataMedicineState)

  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      if (!dataMedicine.length) {
        setLoading(true)
        fetchDataMedicine((data, err) => {
          if (data.status) {
            setDataMedicine(data.data)
            setLoading(false)
            isApiSubscribed = false
          } else {
            if (err) {
              if (err.redirect) {
                localStorage.removeItem(STORAGE_TOKEN_KEY)
                history.push('/login')
                setLoading(false)
              }
            }
          }
        })
      }
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [setDataMedicine, history, setLoading, dataMedicine.length])

  return { dataMedicine, setDataMedicine }
}

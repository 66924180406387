import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

export const fetchSatuSehatConfig = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`satusehat/config`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const updateSatuSehatConfig = async (payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`satusehat/config`, payload)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const authCheck = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`satusehat/auth-check`)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    // errorResponse(error, cb)
  }
}

export const organizationCheck = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`satusehat/organization-check`)

    if (data.status) {
      cb(data.data.name)
    }
  } catch (error) {
    if (error.response.status === 404) {
      cb(error.response.data.message)
    } else {
      // errorResponse(error, cb)
    }
  }
}

export const fetchPhysicalType = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`satusehat/location/type`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchAddress = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`satusehat/address`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchTelecom = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`satusehat/telecom`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchGeoPosition = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`satusehat/position`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchLocation = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`satusehat/location`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchOneLocation = async (code, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`satusehat/location/detail/${code}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const serviceLocationState = atom({
  key: 'serviceLocationState',
  default: { rows: [], meta: {} },
})

export const fetchLocationPagination = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`satusehat/location/pagination?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchClass = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`satusehat/class`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const createLocation = async (payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`satusehat/location`, payload)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const updateLocation = async (params, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`satusehat/location/${params}`, payload)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const createGeoPosition = async (payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`satusehat/position`, payload)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
export const updateGeoPosition = async (params, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`satusehat/position/${params}`, payload)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const createTelecom = async (payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`satusehat/telecom`, payload)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
export const updateTelecom = async (params, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`satusehat/telecom/${params}`, payload)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const createAddress = async (payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`satusehat/address`, payload)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
export const updateAddress = async (params, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`satusehat/address/${params}`, payload)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch leads
export const leadsPaginationState = atom({
  key: 'leadsPaginationState',
  default: { rows: [], meta: {} },
})
export const fetchLeadsPagination = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leads?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch one lead
export const leadsOneState = atom({
  key: 'leadsOneState',
  default: null,
})
export const fetchLeadOne = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leads/${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch lead marketing
export const leadMarketingActivityState = atom({
  key: 'leadMarketingActivityState',
  default: { rows: [], meta: {} },
})
export const fetchLeadMarketingActivity = async (leadsId, params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leads/${leadsId}/marketing?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// create lead appointment
export const createLeadAppointment = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('leads/appointment', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// create lead call
export const createLeadCall = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('leads/call', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// lead target
export const fetchLeadTarget = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leads/target`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const updateLeadTarget = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`leads/target`, input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const requestConvertLead = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`leads/request-convert/${id}`)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// create leads
export const createLeads = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('leads/new-data', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchListRequestedLead = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leads/requested-list`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const listLeadOwnerState = atom({
  key: 'listLeadOwnerState',
  default: [],
})
export const fetchListLeadOwner = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leads/marketing-staff/list`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const changeLeadOwner = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch('leads/change/owner', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const listStatusLeadState = atom({
  key: 'listStatusLeadState',
  default: [],
})
export const fetchListStatusLead = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leads/status/list`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// status nya lead
export const changeLeadStatus = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch('leads/change/status', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const createLeadStatus = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('leads/status', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// update nama status
export const changeLeadStatusName = async (id, input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`leads/status/${id}`, input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const deleteLeadStatus = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete(`leads/status/${id}`)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const listSourceLeadState = atom({
  key: 'listSourceLeadState',
  default: [],
})

export const fetchListSourceLead = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leads/source/list`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
export const createLeadSource = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('leads/source', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const changeLeadSourceName = async (id, input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`leads/source/${id}`, input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const deleteLeadSource = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete(`leads/source/${id}`)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const changeLeadSource = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch('leads/change/source', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { fetchFMTreatmentDetailRecord } from '../../store'
import { STORAGE_TOKEN_KEY } from '../../api/constant'

export default function useFetchDataSupplementOrMedication(
  setLoading,
  setInputArray,
  typeView,
  medicalRecordType,
  supplementId,
) {
  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed && typeView === 'VIEW') {
      setLoading(true)
      fetchFMTreatmentDetailRecord(medicalRecordType, '', supplementId, (data, err) => {
        if (data) {
          if (medicalRecordType === 'supplement') {
            setInputArray([...data.FmtSupplementDetail])
          } else {
            setInputArray([...data.FmtMedicationDetail])
          }
          setLoading(false)
          if (!data.length) {
            isApiSubscribed = false
          }
        } else {
          if (err.redirect) {
            localStorage.removeItem(STORAGE_TOKEN_KEY)
            history.push('/login')
            setLoading(false)
          }
        }
      })
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [history, typeView, medicalRecordType, setLoading, setInputArray, supplementId])
}

import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router'
import { useRecoilState } from 'recoil'
import { STORAGE_TOKEN_KEY } from '../api/constant'
import {
  dataActivePatientState,
  dataInactivePatientState,
  dataNewPatientState,
  dataPatientState,
  dataUnverifiedPatientState,
  fetchDataPatient,
} from '../store'

export default function useFetchDataPatientAll(setLoading, status, page = 1) {
  const [dataPatient, setDataPatient] = useRecoilState(dataPatientState)
  const [dataActivePatient, setDataActivePatient] = useRecoilState(dataActivePatientState)
  const [dataInactivePatient, setDataInactivePatient] = useRecoilState(dataInactivePatientState)
  const [dataUnverifiedPatient, setDataUnverifiedPatient] = useRecoilState(
    dataUnverifiedPatientState,
  )
  const [dataNewPatient, setDataNewPatient] = useRecoilState(dataNewPatientState)

  const history = useHistory()

  let obj = useMemo(() => {
    return {
      params: null,
      data: null,
      setData: null,
    }
  }, [])
  if (status === 'active') {
    obj.params = 'status=active&orderBy=createdAt&orderDir=desc&page=' + page
    obj.data = dataActivePatient
    obj.setData = setDataActivePatient
  } else if (status === 'inactive') {
    obj.params = 'status=inactive&orderBy=createdAt&orderDir=desc&page=' + page
    obj.data = dataInactivePatient
    obj.setData = setDataInactivePatient
  } else if (status === 'unverified') {
    obj.params = 'status=unverified&orderBy=createdAt&orderDir=desc&page=' + page
    obj.data = dataUnverifiedPatient
    obj.setData = setDataUnverifiedPatient
  } else if (status === 'new') {
    obj.params = 'showOnlyLatest=true&orderBy=createdAt&orderDir=desc&page=' + page
    obj.data = dataNewPatient
    obj.setData = setDataNewPatient
  } else {
    obj.params = 'orderBy=createdAt&orderDir=desc&page=' + page
    obj.data = dataPatient
    obj.setData = setDataPatient
  }

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      if (status === '') {
        if (!Object.keys(obj.data.meta).length) {
          setLoading(true)

          fetchDataPatient(obj.params, (data, err) => {
            if (data) {
              obj.setData(data)
              setLoading(false)
            } else {
              if (err.redirect) {
                localStorage.removeItem(STORAGE_TOKEN_KEY)
                history.push('/login')
                setLoading(false)
              }
            }
          })
        }
      } else {
        setLoading(true)

        fetchDataPatient(obj.params, (data, err) => {
          if (data) {
            obj.setData(data)
            setLoading(false)
          } else {
            if (err.redirect) {
              localStorage.removeItem(STORAGE_TOKEN_KEY)
              history.push('/login')
              setLoading(false)
            }
          }
        })
      }
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [obj, setLoading, status, history, page])

  return {
    dataPatient,
    dataActivePatient,
    dataInactivePatient,
    dataUnverifiedPatient,
    dataNewPatient,
    setDataActivePatient,
    setDataPatient,
    setDataInactivePatient,
    setDataNewPatient,
  }
}

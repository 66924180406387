import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import PropTypes from 'prop-types'

const borderColor = '#d9da55'
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: '#d9da55',
    backgroundColor: '#afbc21',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  title: {
    color: '#015287',
    fontWeight: 'bold',
    height: '100%',
    paddingTop: 8,
  },
  borderRight: {
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
})

const border = (border) => (border ? styles.borderRight : null)

const TableHeader = ({ headers }) => (
  <View style={styles.container}>
    {headers.map((head, i) => (
      <Text key={i} style={{ ...styles.title, ...border(head.border), width: head.width }}>
        {head.title}
      </Text>
    ))}
  </View>
)

TableHeader.propTypes = {
  headers: PropTypes.array,
}

export default TableHeader

import { View, Text, StyleSheet } from '@react-pdf/renderer'
import React from 'react'
import Checkbox from '../components/Checkbox'
import PropTypes from 'prop-types'

Sleep.propTypes = {
  data: PropTypes.array,
}

export default function Sleep({ data }) {
  const styles = StyleSheet.create({
    title: { marginLeft: 5, width: '50%' },
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    label: {
      display: 'flex',
      flexDirection: 'row',
      margin: 5,
    },
    underScore: {
      borderBottom: '1px solid black',
      flex: 1,
      padding: 0,
      paddingLeft: 5,
      marginLeft: 10,
    },
  })

  return (
    <>
      <View style={styles.label}>
        <Text>How many hours of sleep do you get each night on average? :</Text>
        <Text style={styles.underScore}>{data[0].answer}</Text>
      </View>

      {data?.slice(1, data?.length - 1).map((el) => (
        <View key={el.id} style={styles.container}>
          <Text style={styles.title}>{el.question}</Text>
          {el.answer &&
            JSON.parse(el.answer).map((check) => (
              <Checkbox
                key={check.code}
                isCheck={check.selected}
                text={check.value}
                style={{ margin: 5 }}
              />
            ))}
        </View>
      ))}

      <View style={styles.label}>
        <Text>If yes, explain :</Text>
        <Text style={styles.underScore}>{data[data.length - 1]?.answer}</Text>
      </View>
    </>
  )
}

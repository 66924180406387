import { useEffect } from 'react'
import { useHistory } from 'react-router'

import { STORAGE_TOKEN_KEY } from '../api/constant'
import { fetchMSQFormByPatient } from '../store'

export default function useFetchMsqHistory(params, data, setData, setLoading, medicalId) {
  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      setLoading(true)
      fetchMSQFormByPatient(medicalId, params, (_data, err) => {
        if (_data) {
          setData(_data)
          setLoading(false)
          isApiSubscribed = false
        } else {
          if (err) {
            if (err.redirect) {
              localStorage.removeItem(STORAGE_TOKEN_KEY)
              history.push('/login')
              setLoading(false)
            }
          }
        }
      })
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, params, medicalId])

  return { data, setData }
}

import React from 'react'
import { Page, Document, StyleSheet, View, Text } from '@react-pdf/renderer'
// import Kop from './Kop'
// import Description from './Description'
// import MsqForm from './MsqForm'
import PropTypes from 'prop-types'
import Kop from './Kop'
import GeneralInformation from './GeneralInformation'
import HealthGoals from './HealthGoals'
import ReadinessAssessment from './ReadinessAssessment'
import Medications from './Medications'
import SymptomReview from './SymptomReview'
import MedicalHistory from './MedicalHistory'
import CurrentHealthConcern from './CurrentHealthConcern/CurrentHealthConcern'
import Allergies from './Allergies'
import LivestyleReview from './LifestyleReview/index'
import History from './History'

Index.propTypes = {
  data: PropTypes.object,
}

export default function Index({ data }) {
  const styles = StyleSheet.create({
    page: {
      fontSize: 10,
      paddingTop: 20,
      paddingBottom: 20,
      paddingHorizontal: 25,
    },
  })

  const GI = []
  let CHC = []
  let ALG = []
  const LSR = {
    SLP: [],
    EXC: [],
    NTT: [],
    DET: [],
    SMK: [],
    ACH: [],
    OST: [],
    STS: [],
    RTS: [],
  }
  const HST = {
    PBC: [],
    DNL: [],
    EDH: [],
    MNH: [],
    WNH: [],
    FMH: [],
    MHI: [],
  }
  const SYR = []
  const MDC = {
    CRT: [],
    NTN: [],
    ATB: [],
    STR: [],
  }
  const RAH = {
    RA: [],
    HG: [],
  }

  for (let i = 0; i < data.IntakeQuestion.length; i++) {
    const intake = data.IntakeQuestion[i]

    if (intake.h1_code === 'GI') {
      GI.push(intake)
    } else if (intake.h1_code === 'CHC') {
      if (intake.answer) {
        CHC = [...JSON.parse(intake.answer)]
      }
    } else if (intake.h1_code === 'ALG') {
      if (intake.answer) {
        ALG = [...JSON.parse(intake.answer)]
      }
    } else if (intake.h1_code === 'LSR') {
      if (intake.h2_code === 'SLP') {
        LSR.SLP.push(intake)
      } else if (intake.h2_code === 'EXC') {
        LSR.EXC.push(intake)
      } else if (intake.h2_code === 'NTT') {
        LSR.NTT.push(intake)
      } else if (intake.h2_code === 'DET') {
        LSR.DET.push(intake)
      } else if (intake.h2_code === 'SMK') {
        LSR.SMK.push(intake)
      } else if (intake.h2_code === 'ACH') {
        LSR.ACH.push(intake)
      } else if (intake.h2_code === 'OST') {
        LSR.OST.push(intake)
      } else if (intake.h2_code === 'STS') {
        LSR.STS.push(intake)
      } else if (intake.h2_code === 'RTS') {
        LSR.RTS.push(intake)
      }
    } else if (intake.h1_code === 'HST') {
      if (intake.h2_code === 'PBC') {
        HST.PBC.push(intake)
      } else if (intake.h2_code === 'DNL') {
        HST.DNL.push(intake)
      } else if (intake.h2_code === 'EDH') {
        HST.EDH.push(intake)
      } else if (intake.h2_code === 'MNH') {
        HST.MNH.push(intake)
      } else if (intake.h2_code === 'WNH') {
        HST.WNH.push(intake)
      } else if (intake.h2_code === 'FMH') {
        HST.FMH.push(intake)
      } else if (intake.h2_code === 'MHI') {
        HST.MHI.push(intake)
      }
    } else if (intake.h1_code === 'SYR') {
      SYR.push(intake)
    } else if (intake.h1_code === 'MDC') {
      if (intake.h2_code === 'CRT') {
        MDC.CRT.push(intake)
      } else if (intake.h2_code === 'NTN') {
        MDC.NTN.push(intake)
      } else if (intake.h2_code === 'ATB') {
        MDC.ATB.push(intake)
      } else if (intake.h2_code === 'STR') {
        MDC.STR.push(intake)
      }
    } else if (intake.h1_code === 'RAH') {
      if (intake.h2_code === 'RA') {
        RAH.RA.push(intake)
      } else if (intake.h2_code === 'HG') {
        RAH.HG.push(intake)
      }
    }
  }

  return (
    <Document>
      <Page wrap size="A4" style={styles.page}>
        <Kop data={data} />
        {/* GI Ok */}
        <GeneralInformation data={GI} />
        {/* CHC OK */}
        <CurrentHealthConcern data={CHC} />
        {/* Allergies OK */}
        <Allergies data={ALG} />
        {/* Live OK */}
        <LivestyleReview data={LSR} />

        {/* HST */}
        <History data={HST} type={data.type} />
        <MedicalHistory data={HST.MHI} type={data.type} />
        {/* HST */}

        <View style={{ paddingLeft: 10, paddingRight: 10 }}>
          <SymptomReview data={SYR} type={data.type} />
        </View>

        <View style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Medications data={MDC} />
        </View>

        {/* RAH */}
        <View style={{ paddingLeft: 10, paddingRight: 10 }}>
          <ReadinessAssessment data={RAH.RA} />
        </View>
        <View style={{ paddingLeft: 10, paddingRight: 10, marginTop: 20 }}>
          <Text style={{ ...styles.fontBold, marginBottom: 10 }}>Health Goals</Text>
          <HealthGoals data={RAH.HG} />
        </View>
        {/* RAH */}
      </Page>
    </Document>
  )
}

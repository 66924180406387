import { CFormInput } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useDebounce } from '../../hooks'
import PropTypes from 'prop-types'

ShowEntriesWithPagination.propTypes = {
  setData: PropTypes.func,
  fetchData: PropTypes.func,
  page: PropTypes.number,
  size: PropTypes.number,
  setSize: PropTypes.func,
  params: PropTypes.string,
  nested: PropTypes.bool,
  setPage: PropTypes.func,
  medicalId: PropTypes.string,
  whosFetch: PropTypes.string,
  setWhosFetch: PropTypes.func,
}

export default function ShowEntriesWithPagination({
  fetchData,
  params = '',
  setData,
  page,
  size = 10,
  setSize = null,
  setPage = null,
  // nested untuk kirim balikan yg nested
  nested = false,
  medicalId = null,
  whosFetch = '',
  setWhosFetch = () => {},
}) {
  const [input, setInput] = useState(size)
  const [isChange, setIsChange] = useState(false)

  const inputValue = useDebounce(input)

  useEffect(() => {
    if (isChange && whosFetch === 'showEntries') {
      let paramData
      if (!inputValue) {
        paramData = `${params}size=${inputValue}&page=${1}`
        if (setPage !== null) {
          setPage(1)
          setSize(10)
        }
      } else {
        setSize(Number(inputValue))
        paramData = `${params}size=${inputValue}&page=${1}`
        setPage(1)
      }
      if (medicalId) {
        fetchData(medicalId, paramData, (data, err) => {
          if (nested) {
            setData(data.data)
          } else {
            setData(data)
          }
        })
      } else {
        fetchData(paramData, (data, err) => {
          if (nested) {
            setData(data.data)
          } else {
            setData(data)
          }
        })
      }
    }

    return () => {
      // setIsChange(false)
    }
  }, [
    fetchData,
    inputValue,
    isChange,
    setData,
    page,
    setSize,
    size,
    params,
    nested,
    setPage,
    medicalId,
    whosFetch,
  ])

  return (
    <div className="d-flex " style={{ width: '160px', height: '40px', alignItems: 'center' }}>
      <small className="me-2">Show</small>
      <CFormInput
        type="number"
        min="1"
        className="me-2"
        value={input}
        onChange={(e) => {
          setInput(e.target.value)
          setIsChange(true)
          setWhosFetch('showEntries')
        }}
      />

      <small>Entries</small>
    </div>
  )
}

import { View, Text, StyleSheet } from '@react-pdf/renderer'
import React from 'react'
import TableCheckbox from '../components/TableCheckbox'
import PropTypes from 'prop-types'
import { upperCaseFirstLetter } from '../../../../../../helper/upperCaseFirstLetter'

index.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string,
}

export default function index({ data, type }) {
  function formatData(array) {
    return array.map((el) => {
      return {
        title: {
          data: { type: 'text', text: el.question },
          width: '70%',
          border: true,
        },
        mild: { data: { type: 'checkbox', check: el.mild }, width: '10%', border: true },
        moderate: { data: { type: 'checkbox', check: el.moderate }, width: '10%', border: true },
        severe: { data: { type: 'checkbox', check: el.severe }, width: '10%', border: false },
      }
    })
  }

  // general
  const headGeneral = [
    {
      title: 'General',
      width: '70%',
      border: true,
    },
    {
      title: 'Mild',
      width: '10%',
      border: true,
    },
    {
      title: 'Moderate',
      width: '10%',
      border: true,
    },
    {
      title: 'Severe',
      width: '10%',
      border: false,
    },
  ]
  const itemGeneral = formatData(JSON.parse(data[0].answer))

  //head eys and ears
  const headHead = [
    {
      title: 'Head, Eyes, and Ears',
      width: '70%',
      border: true,
    },
    {
      title: 'Mild',
      width: '10%',
      border: true,
    },
    {
      title: 'Moderate',
      width: '10%',
      border: true,
    },
    {
      title: 'Severe',
      width: '10%',
      border: false,
    },
  ]
  const itemHead = formatData(JSON.parse(data[1].answer))

  //Musculoskeletal
  const headMuscu = [
    {
      title: 'Musculoskeletal',
      width: '70%',
      border: true,
    },
    {
      title: 'Mild',
      width: '10%',
      border: true,
    },
    {
      title: 'Moderate',
      width: '10%',
      border: true,
    },
    {
      title: 'Severe',
      width: '10%',
      border: false,
    },
  ]
  const itemMuscu = formatData(JSON.parse(data[2].answer))

  //Mood / Nerves
  const headMood = [
    {
      title: 'Mood/Nerves',
      width: '70%',
      border: true,
    },
    {
      title: 'Mild',
      width: '10%',
      border: true,
    },
    {
      title: 'Moderate',
      width: '10%',
      border: true,
    },
    {
      title: 'Severe',
      width: '10%',
      border: false,
    },
  ]
  const itemMood = formatData(JSON.parse(data[3].answer))

  //Cardiovascular
  const headCardio = [
    {
      title: 'Cardiovascular',
      width: '70%',
      border: true,
    },
    {
      title: 'Mild',
      width: '10%',
      border: true,
    },
    {
      title: 'Moderate',
      width: '10%',
      border: true,
    },
    {
      title: 'Severe',
      width: '10%',
      border: false,
    },
  ]
  const itemCardio = formatData(JSON.parse(data[4].answer))

  //Urinary
  const headUrinary = [
    {
      title: 'Urinary',
      width: '70%',
      border: true,
    },
    {
      title: 'Mild',
      width: '10%',
      border: true,
    },
    {
      title: 'Moderate',
      width: '10%',
      border: true,
    },
    {
      title: 'Severe',
      width: '10%',
      border: false,
    },
  ]
  const itemUrinary = formatData(JSON.parse(data[5].answer))

  //Digestion
  const headDigestion = [
    {
      title: 'Digestion',
      width: '70%',
      border: true,
    },
    {
      title: 'Mild',
      width: '10%',
      border: true,
    },
    {
      title: 'Moderate',
      width: '10%',
      border: true,
    },
    {
      title: 'Severe',
      width: '10%',
      border: false,
    },
  ]
  const itemDigestion = formatData(JSON.parse(data[6].answer))

  // Eating
  const headEating = [
    {
      title: 'Eating',
      width: '70%',
      border: true,
    },
    {
      title: 'Mild',
      width: '10%',
      border: true,
    },
    {
      title: 'Moderate',
      width: '10%',
      border: true,
    },
    {
      title: 'Severe',
      width: '10%',
      border: false,
    },
  ]
  const itemEating = formatData(JSON.parse(data[7].answer))

  // Respiratory
  const headRespiratory = [
    {
      title: 'Respiratory',
      width: '70%',
      border: true,
    },
    {
      title: 'Mild',
      width: '10%',
      border: true,
    },
    {
      title: 'Moderate',
      width: '10%',
      border: true,
    },
    {
      title: 'Severe',
      width: '10%',
      border: false,
    },
  ]
  const itemRespiratory = formatData(JSON.parse(data[8].answer))

  // nails
  const headNails = [
    {
      title: 'Nails',
      width: '70%',
      border: true,
    },
    {
      title: 'Mild',
      width: '10%',
      border: true,
    },
    {
      title: 'Moderate',
      width: '10%',
      border: true,
    },
    {
      title: 'Severe',
      width: '10%',
      border: false,
    },
  ]
  const itemNails = formatData(JSON.parse(data[9].answer))

  // Lymph Nodes
  const headLymph = [
    {
      title: 'Lymph Nodes',
      width: '70%',
      border: true,
    },
    {
      title: 'Mild',
      width: '10%',
      border: true,
    },
    {
      title: 'Moderate',
      width: '10%',
      border: true,
    },
    {
      title: 'Severe',
      width: '10%',
      border: false,
    },
  ]
  const itemLymph = formatData(JSON.parse(data[10].answer))

  // Skin, Dryness of
  const headSkinDry = [
    {
      title: 'Skin, Dryness of',
      width: '70%',
      border: true,
    },
    {
      title: 'Mild',
      width: '10%',
      border: true,
    },
    {
      title: 'Moderate',
      width: '10%',
      border: true,
    },
    {
      title: 'Severe',
      width: '10%',
      border: false,
    },
  ]
  const itemSkinDry = formatData(JSON.parse(data[11].answer))

  // Skin Problems
  const headSkinProb = [
    {
      title: 'Skin Problems',
      width: '70%',
      border: true,
    },
    {
      title: 'Mild',
      width: '10%',
      border: true,
    },
    {
      title: 'Moderate',
      width: '10%',
      border: true,
    },
    {
      title: 'Severe',
      width: '10%',
      border: false,
    },
  ]
  const itemSkinProb = formatData(JSON.parse(data[12].answer))

  // Itching Skin
  const headItching = [
    {
      title: 'Itching Skin',
      width: '70%',
      border: true,
    },
    {
      title: 'Mild',
      width: '10%',
      border: true,
    },
    {
      title: 'Moderate',
      width: '10%',
      border: true,
    },
    {
      title: 'Severe',
      width: '10%',
      border: false,
    },
  ]
  const itemItching = formatData(JSON.parse(data[13].answer))

  // Male Reproductive
  const headByGender = [
    {
      title: `${upperCaseFirstLetter(type)} Reproductive`,
      width: '70%',
      border: true,
    },
    {
      title: 'Mild',
      width: '10%',
      border: true,
    },
    {
      title: 'Moderate',
      width: '10%',
      border: true,
    },
    {
      title: 'Severe',
      width: '10%',
      border: false,
    },
  ]
  const itemByGender = formatData(JSON.parse(data[14].answer))

  const styles = StyleSheet.create({
    fontBold: {
      fontWeight: 'bold',
      color: '#AF924D',
      marginRight: 5,
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 10,
    },
    container: {
      marginTop: 30,
    },
  })

  return (
    <View style={styles.container}>
      <Text style={{ ...styles.fontBold, marginBottom: 10 }}>Symptom Review</Text>
      <View style={styles.title}>
        <Text style={styles.fontBold}>Please check</Text>
        <Text> if these symptoms occur presently or have occurred in the last 6 months</Text>
      </View>
      <TableCheckbox headers={headGeneral} items={itemGeneral} />
      <TableCheckbox headers={headHead} items={itemHead} />
      <View style={{ marginTop: 4 }}></View>
      <TableCheckbox headers={headMuscu} items={itemMuscu} />
      <View style={{ marginTop: 11 }}></View>
      <TableCheckbox headers={headMood} items={itemMood} />
      <TableCheckbox headers={headCardio} items={itemCardio} />
      <TableCheckbox headers={headUrinary} items={itemUrinary} />
      <TableCheckbox headers={headDigestion} items={itemDigestion} />
      <View style={{ marginTop: 12 }}></View>
      <TableCheckbox headers={headEating} items={itemEating} />
      <TableCheckbox headers={headRespiratory} items={itemRespiratory} />
      <View style={{ marginTop: 16 }}></View>
      <TableCheckbox headers={headNails} items={itemNails} />
      <TableCheckbox headers={headLymph} items={itemLymph} />
      <TableCheckbox headers={headSkinDry} items={itemSkinDry} />
      <TableCheckbox headers={headSkinProb} items={itemSkinProb} />
      <View style={{ marginTop: 14 }}></View>
      <TableCheckbox headers={headItching} items={itemItching} />
      <TableCheckbox headers={headByGender} items={itemByGender} />
    </View>
  )
}

import { View, Text, StyleSheet } from '@react-pdf/renderer'
import React from 'react'
import TableCheckbox from '../components/TableCheckbox'
import Table from '../Table'
import PropTypes from 'prop-types'

index.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string,
}

export default function index({ data, type }) {
  function formatDataOne(array) {
    return array.map((el) => {
      return {
        title: {
          data: { type: 'text', text: el.fillable ? `${el.question}: ${el.input}` : el.question },
          width: '70%',
          border: true,
        },
        yes: { data: { type: 'checkbox', check: el.yes }, width: '15%', border: true },
        past: { data: { type: 'checkbox', check: el.past }, width: '15%', border: false },
      }
    })
  }

  function formatDataTwo(array) {
    return array.map((el) => {
      // title: { data: 'Bone density', width: '25%', border: true },
      // date: { data: '-', width: '25%', border: true },
      // comment: { data: '-', width: '50%', border: true },

      return {
        title: {
          data: el.fillable ? `${el.question}: ${el.input}` : el.question,
          width: '25%',
          border: true,
        },
        date: { data: el.date, width: '25%', border: true },
        comment: { data: el.comment, width: '50%', border: false },
      }
    })
  }
  // Gastrointestinal
  const headGastro = [
    {
      title: 'Gastrointestinal',
      width: '70%',
      border: true,
    },
    {
      title: 'Yes',
      width: '15%',
      border: true,
    },

    {
      title: 'Past',
      width: '15%',
      border: false,
    },
  ]
  const itemsGastro = formatDataOne(JSON.parse(data[0].answer))

  // Respiratory
  const headRespi = [
    {
      title: 'Respiratory',
      width: '70%',
      border: true,
    },
    {
      title: 'Yes',
      width: '15%',
      border: true,
    },

    {
      title: 'Past',
      width: '15%',
      border: false,
    },
  ]
  const itemsRespi = formatDataOne(JSON.parse(data[1].answer))

  // Urinary/Genital

  const headUri = [
    {
      title: 'Urinary/Genital',
      width: '70%',
      border: true,
    },
    {
      title: 'Yes',
      width: '15%',
      border: true,
    },

    {
      title: 'Past',
      width: '15%',
      border: false,
    },
  ]
  const itemsUri = formatDataOne(JSON.parse(data[2].answer))

  // Endocrine/Metabolic
  const headEndo = [
    {
      title: 'Endocrine/Metabolic',
      width: '70%',
      border: true,
    },
    {
      title: 'Yes',
      width: '15%',
      border: true,
    },
    {
      title: 'Past',
      width: '15%',
      border: false,
    },
  ]
  const itemsEndo = formatDataOne(JSON.parse(data[3].answer))

  // Inflammatory/Immune
  const headInfla = [
    {
      title: 'Inflammatory/Immune',
      width: '70%',
      border: true,
    },
    {
      title: 'Yes',
      width: '15%',
      border: true,
    },
    {
      title: 'Past',
      width: '15%',
      border: false,
    },
  ]
  const itemsInfla = formatDataOne(JSON.parse(data[4].answer))

  // Musculoskeletal
  const headMuscu = [
    {
      title: 'Musculoskeletal',
      width: '70%',
      border: true,
    },
    {
      title: 'Yes',
      width: '15%',
      border: true,
    },
    {
      title: 'Past',
      width: '15%',
      border: false,
    },
  ]
  const itemsMuscu = formatDataOne(JSON.parse(data[5].answer))

  // Skin
  const headSkin = [
    {
      title: 'Skin',
      width: '70%',
      border: true,
    },
    {
      title: 'Yes',
      width: '15%',
      border: true,
    },
    {
      title: 'Past',
      width: '15%',
      border: false,
    },
  ]
  const itemsSkin = formatDataOne(JSON.parse(data[6].answer))

  // Cardiovascular
  const headCardio = [
    {
      title: 'Cardiovascular',
      width: '70%',
      border: true,
    },
    {
      title: 'Yes',
      width: '15%',
      border: true,
    },
    {
      title: 'Past',
      width: '15%',
      border: false,
    },
  ]
  const itemsCardio = formatDataOne(JSON.parse(data[7].answer))

  // Neurologic/Emotional
  const headNeuro = [
    {
      title: 'Neurologic/Emotional',
      width: '70%',
      border: true,
    },
    {
      title: 'Yes',
      width: '15%',
      border: true,
    },
    {
      title: 'Past',
      width: '15%',
      border: false,
    },
  ]
  const itemsNeuro = formatDataOne(JSON.parse(data[8].answer))

  // Cancer
  const headCancer = [
    {
      title: 'Cancer',
      width: '70%',
      border: true,
    },
    {
      title: 'Yes',
      width: '15%',
      border: true,
    },
    {
      title: 'Past',
      width: '15%',
      border: false,
    },
  ]
  const itemsCancer = formatDataOne(JSON.parse(data[9].answer))

  // Diagnostic Studies
  const headDiag = [
    {
      title: 'Diagnostic Studies',
      width: '25%',
      border: true,
    },
    {
      title: 'Date',
      width: '25%',
      border: true,
    },
    {
      title: 'Comment',
      width: '50%',
      border: false,
    },
  ]
  const itemDiag = formatDataTwo(JSON.parse(data[10].answer))

  // Injuries
  const headInjury = [
    {
      title: 'Injuries',
      width: '25%',
      border: true,
    },
    {
      title: 'Date',
      width: '25%',
      border: true,
    },
    {
      title: 'Comments',
      width: '50%',
      border: false,
    },
  ]
  const itemInjury = formatDataTwo(JSON.parse(data[11].answer))

  // Surgeries
  const headSurgery = [
    {
      title: 'Surgeries',
      width: '25%',
      border: true,
    },
    {
      title: 'Date',
      width: '25%',
      border: true,
    },
    {
      title: 'Comments',
      width: '50%',
      border: false,
    },
  ]
  const itemSurgery = formatDataTwo(JSON.parse(data[12].answer))

  // Hospitalizations
  const headHospital = [
    {
      title: 'Hospitalizations',
      width: '25%',
      border: true,
    },
    {
      title: 'Date',
      width: '25%',
      border: true,
    },
    {
      title: 'Reason',
      width: '50%',
      border: false,
    },
  ]
  const itemHospital = data[13].answer
    ? JSON.parse(data[13].answer).map((el) => {
        return {
          title: { data: el.hospitalizations, width: '25%', border: true },
          date: { data: el.date, width: '25%', border: true },
          comment: { data: el.reason, width: '50%', border: true },
        }
      })
    : [
        {
          title: { data: '', width: '25%', border: true },
          date: { data: '', width: '25%', border: true },
          comment: { data: '', width: '50%', border: true },
        },
        {
          title: { data: '', width: '25%', border: true },
          date: { data: '', width: '25%', border: true },
          comment: { data: '', width: '50%', border: true },
        },
      ]

  const styles = StyleSheet.create({
    fontBold: {
      fontWeight: 'bold',
      color: '#AF924D',
      marginRight: 5,
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 10,
    },
    container: {
      marginTop: 20,
    },
  })

  return (
    <View style={styles.container}>
      <Text style={{ ...styles.fontBold, marginBottom: 10 }}>
        Medical History: Illnesses/Conditions
      </Text>
      <View style={styles.title}>
        <Text style={styles.fontBold}>Check YES</Text>
        <Text> = a condition you currently have,</Text>
        <Text style={styles.fontBold}>Check PAST</Text>
        <Text> = a condition you&apos;ve had in the past.</Text>
      </View>
      <TableCheckbox headers={headGastro} items={itemsGastro} />
      {type === 'female' ? (
        <View style={{ marginTop: 13 }}></View>
      ) : (
        <View style={{ marginTop: 67 }}></View>
      )}

      <TableCheckbox headers={headRespi} items={itemsRespi} />
      <TableCheckbox headers={headUri} items={itemsUri} />
      <TableCheckbox headers={headEndo} items={itemsEndo} />
      <TableCheckbox headers={headInfla} items={itemsInfla} />
      <TableCheckbox headers={headMuscu} items={itemsMuscu} />
      <TableCheckbox headers={headSkin} items={itemsSkin} />
      <View style={{ marginTop: 3 }}></View>
      <TableCheckbox headers={headCardio} items={itemsCardio} />
      <TableCheckbox headers={headNeuro} items={itemsNeuro} />
      <TableCheckbox headers={headCancer} items={itemsCancer} />

      <View style={{ marginTop: 28 }}></View>
      <Table headers={headDiag} items={itemDiag} />
      <Table headers={headInjury} items={itemInjury} />
      <Table headers={headSurgery} items={itemSurgery} />
      <Table headers={headHospital} items={itemHospital} />
    </View>
  )
}

import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// create role
export const createUserResponse = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('leads/surveys', input)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch data survey response
export const dataSurveyResponseState = atom({
  key: 'dataSurveyResponseState',
  default: { meta: {}, rows: [] },
})

export const fetchDataSurveyResponse = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leads/surveys/responses?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch one
export const fetchUserResponseOne = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leads/surveys/response/${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// otp verification
export const checkOtp = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('leads/verification', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// resend otp
export const resendOtp = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('leads/resendotp', input)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// change number for otp
export const changeNumberForOTP = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch('leads/changenumber', input)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch survey question
export const surveyQuestionsState = atom({
  key: 'surveyQuestionsState',
  default: [],
})

export const fetchSurveyQuestions = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leads/surveys`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// create video link
export const createVideoLink = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('leads/surveys/addvideo', input)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchExportUserResponse = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('leads/surveys/responses/export', params)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

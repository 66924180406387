import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

//fetch call activity
export const fetchReportingCallActivity = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leads/summary/callactivity?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportingLeadStatus = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leads/summary/leadstatus?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

//fetch appointment activity
export const fetchReportingAppointmentActivity = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leads/summary/appointmentactivity?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportingPromotion = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`promotions/summary?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportingVoucher = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`vouchers/summary?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

//fetch package activity
export const fetchReportingPackage = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`packages/summary?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

//fetch production performance
export const fetchProductionPerformance = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leads/summary/productionperformance?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportingLoyalty = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`loyalties/summary?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportingActivity = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leads/summary/activity?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
// ########### Detail

export const fetchReportLeadsDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leads/summary?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportPromotionDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`promotions/summary/detail?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportPackageDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`packages/summary/detail?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportLoyaltyDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`loyalties/summary/detail?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportVoucherDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`vouchers/summary/detail?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportingLeadSource = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leads/summary/leadsource?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

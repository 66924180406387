import { View, Text, StyleSheet } from '@react-pdf/renderer'
import React from 'react'
import Checkbox from '../components/Checkbox'
import PropTypes from 'prop-types'

Smoking.propTypes = {
  data: PropTypes.array,
}

export default function Smoking({ data }) {
  const styles = StyleSheet.create({
    title: {
      fontWeight: 'bold',
      color: '#AF924D',
      marginLeft: 5,
      marginBottom: 10,
    },
    question: {
      marginLeft: 5,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      // marginTop: 10,
    },
    label: {
      display: 'flex',
      flexDirection: 'row',
      margin: 5,
    },
    underScore: {
      borderBottom: '1px solid black',
      flex: 1,
      padding: 0,
      paddingLeft: 5,
      marginLeft: 10,
    },
  })
  return (
    <>
      {data.map((el) => (
        <React.Fragment key={el.id}>
          {el.type === 'checkbox' ? (
            <>
              <View style={styles.container}>
                <Text style={styles.question}>{el.question}</Text>
                {JSON.parse(el.answer).map((check) => (
                  <React.Fragment key={check.code}>
                    {check.fillabel ? (
                      <View style={styles.container}>
                        <Checkbox
                          isCheck={check.checked}
                          text={check.value}
                          style={{ margin: 5 }}
                        />
                        <Text style={styles.underScore}>{check.answer}</Text>
                      </View>
                    ) : (
                      <Checkbox isCheck={check.checked} text={check.value} style={{ margin: 5 }} />
                    )}
                  </React.Fragment>
                ))}
              </View>
            </>
          ) : el.type === 'radio' ? (
            <View style={styles.container}>
              <Text style={styles.question}>{el.question}</Text>
              {JSON.parse(el.answer).map((check) => (
                <Checkbox
                  key={check.code}
                  isCheck={check.selected}
                  text={check.value}
                  style={{ margin: 5 }}
                />
              ))}
            </View>
          ) : el.type === 'text' ? (
            <View style={styles.label}>
              <Text>{el.question}</Text>
              <Text style={styles.underScore}>{el.answer}</Text>
            </View>
          ) : el.type === 'questions_arr' ? (
            <>
              <Text style={{ ...styles.question, marginBottom: 10, marginTop: 10 }}>
                {el.question}
              </Text>
              {JSON.parse(el.answer).map((check) => (
                <View key={check.code} style={styles.label}>
                  <Text>{check.question}</Text>
                  <Text style={styles.underScore}>{check.answer}</Text>
                </View>
              ))}
            </>
          ) : null}
        </React.Fragment>
      ))}
    </>
  )
}

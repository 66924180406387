import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// create officer
export const createOfficer = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('officers', input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch data officer
export const dataOfficerState = atom({
  key: 'dataOfficerState',
  default: { rows: [], meta: {} },
})
export const fetchDataOfficer = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`officers/roles?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch data officer list
export const dataOfficerListState = atom({
  key: 'dataOfficerListState',
  default: [],
})
export const fetchDataOfficerList = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`officers/officer-list${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch data officer by role
export const dataOfficersByRoleState = atom({
  key: 'dataOfficersByRoleState',
  default: { rows: [], meta: {} },
})
export const fetchDataOfficersByRole = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`officers${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch one officer
export const fetchOneOfficer = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`officers/${id}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// update officer
export const updateDataOfficer = async (id, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch('officers/' + id, payload)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch doctor list
export const dataDoctorState = atom({
  key: 'dataDoctorState',
  default: [],
})
export const fetchDataDoctor = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('officers/doctor-list?status=active')

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataTrueDoctor = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('officers/doctor-list-review')

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataDoctorNew = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`officers/doctor-list?status=active&${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch doctor branch list
export const dataDoctorBranchState = atom({
  key: 'dataDoctorBranchState',
  default: [],
})
export const fetchDataBranchDoctor = async (branch, activeDate, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(
      `officers/doctor-list?status=active&branch=${branch}&activeDate=${activeDate}`,
    )

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// delete officer
export const deleteDataOfficer = async (param, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete('officers/' + param)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch AR private
export const dataARPrivateistState = atom({
  key: 'dataARPrivateistState',
  default: [],
})
export const fetchDataArPrivateList = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/payers/list`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchServingStaffList = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/officers/service-staff-list`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const responsibleListState = atom({
  key: 'responsibleListState',
  default: [],
})
export const fetchResponsibleList = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`officers/responsible/primary/list?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const serviceOfficerListState = atom({
  key: 'serviceOfficerListState',
  default: [],
})
export const fetchserviceOfficerList = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`officers/service-officer/list?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { STORAGE_TOKEN_KEY } from '../api/constant'
import { fetchDataBranchDoctor } from '../store'

export default function useFetchDataDoctorList(
  setLoading,
  branch = '',
  activeDate = '',
  indexFetch = 0,
) {
  const [dataDoctorList, setDataDoctorList] = useState([])

  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      if (branch !== '') {
        fetchDataBranchDoctor(branch, activeDate, (data, err) => {
          if (data) {
            const listTemp = dataDoctorList
            listTemp[indexFetch] = data
            setDataDoctorList([...listTemp])
            if (!data.length) {
              isApiSubscribed = false
            }
          } else {
            if (err.redirect) {
              localStorage.removeItem(STORAGE_TOKEN_KEY)
              history.push('/login')
            }
          }
        })
      }
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch, history, indexFetch, activeDate])

  return { dataDoctorList, setDataDoctorList }
}

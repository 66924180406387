import React, { useEffect, useState } from 'react'
import { CButton, CButtonGroup } from '@coreui/react'
import { IconContext } from 'react-icons'
import {
  HiChevronRight,
  HiChevronLeft,
  // HiChevronDoubleRight,
  // HiChevronDoubleLeft,
} from 'react-icons/hi'
import PropTypes from 'prop-types'
import { API } from '../../api/helpers'
import { errorResponse } from '../../helper/getErrorResponse'
import { STORAGE_TOKEN_KEY } from '../../api/constant'
import { useHistory } from 'react-router'

PaginationButtonNew.propTypes = {
  firstPageUrl: PropTypes.string,
  lastPageUrl: PropTypes.string,
  nextPageUrl: PropTypes.string,
  prevPageUrl: PropTypes.string,
  total: PropTypes.number,
  path: PropTypes.string,
  size: PropTypes.number,
  fetchData: PropTypes.func,
  setData: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func,
  medicalId: PropTypes.string,
  whosFetch: PropTypes.string,
  setWhosFetch: PropTypes.func,
  fetchParam: PropTypes.string,
  lastPage: PropTypes.number,
}

export default function PaginationButtonNew({
  nextPageUrl,
  prevPageUrl,
  total,
  path,
  size = 10,
  setData,
  page = 1,
  setPage = null,
  medicalId = null,
  whosFetch = '',
  setWhosFetch = () => {},
  fetchParam = '',
}) {
  const btnStyle = {
    borderColor: '#CCCCCC',
    color: '#2C384AF2',
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
  }

  const [url, setUrl] = useState('')
  const [isFetch, setIsFetch] = useState(false)
  const history = useHistory()
  const lastPage = Math.ceil(total / size)

  // let numShown = 3 // This sets the number of page tabs
  let numShown = Math.ceil(total / size) > 5 ? 5 : Math.ceil(total / size)

  numShown = Math.min(numShown, total)
  let first = page - Math.floor(numShown / 2)
  first = Math.max(first, 1)
  first = Math.min(first, total - numShown + 1)

  const arr = [...Array(numShown)].map((k, i) => {
    return i + first
  })

  useEffect(() => {
    function checkUrl() {
      if (url) {
        return url
      } else {
        return fetchParam
          ? `${path}?page=${page}&size=${size}&${fetchParam}`
          : `${path}?page=${page}&size=${size}`
      }
    }

    const fetchData = async (params, cb) => {
      // console.log('FETCH PAGINATION')
      try {
        const { request } = API(true)
        const { data } = await request.get(checkUrl())
        if (data.status) {
          cb(data.data)
        }
      } catch (error) {
        errorResponse(error, cb)
      }
    }

    if (isFetch && whosFetch === 'pagination') {
      fetchData('', (data, err) => {
        if (!err) {
          setData(data)
        } else {
          if (err.redirect) {
            localStorage.removeItem(STORAGE_TOKEN_KEY)
            history.push('/login')
          }
        }
      })
    }

    return () => {
      setIsFetch(false)
    }
  }, [isFetch, page, path, size, url, history, setData, medicalId, whosFetch, fetchParam])

  return (
    <CButtonGroup style={{ backgroundColor: 'white' }}>
      <CButton
        color="primary"
        variant="outline"
        style={btnStyle}
        onClick={() => {
          setPage(Number(page) - 1)
          setIsFetch(true)
          setUrl('')
          setWhosFetch('pagination')
        }}
        disabled={prevPageUrl ? false : true}
      >
        <IconContext.Provider value={{ size: '1.1em' }}>
          <div>
            <HiChevronLeft />
          </div>
        </IconContext.Provider>
      </CButton>

      {arr.map((el, i) => {
        if (el > 7) {
          console.log('disabled')
        }
        return (
          <div key={i}>
            <CButton
              color="primary"
              variant="outline"
              style={btnStyle}
              value={el}
              active={page === el && true}
              onClick={(e) => {
                setPage(Number(e.target.value))
                setIsFetch(true)
                setUrl('')
                setWhosFetch('pagination')
              }}
              disabled={(el > page && !nextPageUrl) || el > lastPage}
            >
              {el}
            </CButton>
          </div>
        )
      })}

      <CButton
        color="primary"
        variant="outline"
        style={btnStyle}
        onClick={() => {
          setPage(Number(page) + 1)
          setIsFetch(true)
          setUrl('')
          setWhosFetch('pagination')
        }}
        disabled={nextPageUrl ? false : true}
      >
        <IconContext.Provider value={{ size: '1.1em' }}>
          <div>
            <HiChevronRight />
          </div>
        </IconContext.Provider>
      </CButton>
    </CButtonGroup>
  )
}

import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useRecoilState } from 'recoil'
import { STORAGE_TOKEN_KEY } from '../api/constant'
import { dataPatientListState, fetchDataPatientList } from '../store'

export default function useFetchDataPatientList(setLoading) {
  const [dataPatientList, setDataPatientList] = useRecoilState(dataPatientListState)

  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      if (!dataPatientList.length) {
        setLoading(true)
        let params = `?status=active`
        fetchDataPatientList(params, (data, err) => {
          if (data) {
            setDataPatientList(data)
            setLoading(false)
            if (!data.length) {
              isApiSubscribed = false
            }
          } else {
            if (err.redirect) {
              localStorage.removeItem(STORAGE_TOKEN_KEY)
              history.push('/login')
              setLoading(false)
            }
          }
        })
      }
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [dataPatientList.length, setDataPatientList, setLoading, history])

  return { dataPatientList }
}

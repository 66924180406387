import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

export const dataBeauticianAllState = atom({
  key: 'dataBeauticianAllState',
  default: { rows: [], meta: {} },
})

export const dataBeauticianDetailState = atom({
  key: 'dataBeauticianDetailState',
  default: { rows: [], meta: {} },
})

export const fetchDataBeauticianAll = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`registrations?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchBeauticianByMedicalID = async (medicalId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`beautician/${medicalId}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// create officer
export const createBeautician = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('beautician', input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

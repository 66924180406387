import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { dataLabRemarkState, fetchLabRemark } from '../../store'
import { STORAGE_TOKEN_KEY } from '../../api/constant'
import { useRecoilState } from 'recoil'

export default function useFetchLabRemark(id, setLoading) {
  const history = useHistory()

  const [labRemark, setLabRemark] = useRecoilState(dataLabRemarkState)

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      setLoading(true)
      fetchLabRemark(id, '', (data, err) => {
        isApiSubscribed = false

        if (err) {
          if (err.redirect) {
            localStorage.removeItem(STORAGE_TOKEN_KEY)
            history.push('/login')
          }
          isApiSubscribed = false
        } else {
          setLabRemark(data)
        }
        setLoading(false)
      })
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [history, setLoading, setLabRemark, id])

  return { labRemark }
}

import { errorResponse } from '../helper/getErrorResponse'
import { API } from '../api/helpers'
import { atom } from 'recoil'

export const activityLogPaginationState = atom({
  key: 'activityLogPaginationState',
  default: { rows: [], meta: {} },
})

export const dataSubjectListState = atom({
  key: 'dataSubjectListState',
  default: [],
})
export const fetchActivityLog = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`activitylogs?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchSubject = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`activitylogs/subject`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

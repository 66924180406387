import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch parent treatment
export const parentTreatmentsState = atom({
  key: 'parentTreatmentsState',
  default: [],
})

export const fetchParentTreatments = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`treatments/parent?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch list treatment
export const listTreatmentsState = atom({
  key: 'listTreatmentsState',
  default: [],
})

export const listTreatmentLabState = atom({
  key: 'listTreatmentLabState',
  default: [],
})

export const fetchListTreatments = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`treatments/list?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchListTreatmentLab = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`treatments/list-lab?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

import React from 'react'
import { View, StyleSheet } from '@react-pdf/renderer'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import PropTypes from 'prop-types'

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 1,
    borderColor: '#d9da55',
  },
})

// const headers = [
//   {
//     title: 'Item Description',
//     width: '60%',
//     border: true,
//   },
//   {
//     title: 'QTY',
//     width: '10%',
//     border: true,
//   },
//   {
//     title: '@',
//     width: '15%',
//     border: true,
//   },
//   {
//     title: 'Amount',
//     width: '15%',
//     border: false,
//   },
// ]

// const items = [
//   {
//     desc: { data: 'Lorem ipsum dolor sit amet', width: '60%', border: true },
//     qty: { data: '5', width: '10%', border: true },
//     price: { data: '405', width: '15%', border: true },
//     amount: { data: '2029', width: '15%', border: false },
//   },
//   {
//     desc: { data: 'wakwaw', width: '60%', border: true },
//     qty: { data: '5', width: '10%', border: true },
//     price: { data: '405', width: '15%', border: true },
//     amount: { data: '2029', width: '15%', border: false },
//   },
//   {
//     desc: { data: 'yuhuuu', width: '60%', border: true },
//     qty: { data: '5', width: '10%', border: true },
//     price: { data: '405', width: '15%', border: true },
//     amount: { data: '2029', width: '15%', border: false },
//   },
// ]

const Table = ({ headers, items }) => (
  <View style={styles.tableContainer}>
    <TableHeader headers={headers} />
    <TableRow items={items} />
  </View>
)

Table.propTypes = {
  headers: PropTypes.array,
  items: PropTypes.array,
}

export default Table

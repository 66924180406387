import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

/** CATEGORY **/

// create program category
export const createProgramCategory = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('programs/categories', input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch data program category
export const dataProgramCategoryState = atom({
  key: 'dataProgramCategoryState',
  default: { rows: [], meta: {} },
})
export const fetchDataProgramCategory = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`programs/categories${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch one program category
export const fetchOneDataProgramCategory = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`programs/categories/${id}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// update program category
export const updateDataProgramCategory = async (params, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch('programs/categories/' + params, payload)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// delete program category
export const deleteDataProgramCategory = async (param, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete('/programs/categories/' + param)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch program category count
export const dataProgramCategoryCountState = atom({
  key: 'dataProgramCategoryCountState',
  default: { rows: [], meta: {} },
})
export const fetchDataProgramCategoryCount = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`programs/count-category?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

/** PROGRAM **/

// create programs
export const createPrograms = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('programs', input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch data program
export const dataProgramState = atom({
  key: 'dataProgramState',
  default: { rows: [], meta: {} },
})
export const fetchDataProgram = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`programs?category=${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch one program
export const fetchOneDataProgram = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`programs/${id}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// update program
export const updateDataProgram = async (programId, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch('programs/' + programId, payload)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// delete program
export const deleteDataProgram = async (param, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete('programs/' + param)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

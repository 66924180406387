import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

useDebounce.propTypes = {
  value: PropTypes.array,
  delay: PropTypes.number,
}

export default function useDebounce(value, delay = 800) {
  const [debounceValue, setDebounceValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debounceValue
}

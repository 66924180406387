import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch leads
export const intakeFormPatientState = atom({
  key: 'intakeFormPatientState',
  default: { rows: [], meta: {} },
})
export const fetchIntakeFormPatient = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`intake-form/patient?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// assign form to patient
export const assignIntakeFormToPatient = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('intake-form', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// remind patient
export const remindIntakeFormToPatient = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('intake-form/remind-patient', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
export const fetchOneIntakeFormPatient = async (formCode, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`intake-form/${formCode}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const uploadIntakePDF = async (body, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`intake-form/upload-report`, body)
    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

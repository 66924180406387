import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// create request
export const createRequestOnLeave = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('leave', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch my request
export const dataMyRequestOnLeaveState = atom({
  key: 'dataMyRequestOnLeaveState',
  default: [],
})
export const fetchDataMyRequestOnLeaveState = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leave`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch my history
export const dataMyRequestOnLeaveHistoryState = atom({
  key: 'dataMyRequestOnLeaveHistoryState',
  default: { rows: [], meta: {} },
})
export const fetchDataMyRequestOnLeaveHistory = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leave/history?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch employee on leave request
export const dataEmployeeRequestOnLeaveRequestState = atom({
  key: 'dataEmployeeRequestOnLeaveRequestState',
  default: { rows: [], meta: {} },
})
export const fetchDataEmployeeRequestOnLeaveRequest = async (params = 'size=10&page=1', cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leave/approval?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch employee on leave History
export const dataEmployeeRequestOnLeaveHistoryState = atom({
  key: 'dataEmployeeRequestOnLeaveHistoryState',
  default: { rows: [], meta: {} },
})
export const fetchDataEmployeeRequestOnLeaveHistory = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`leave/approval/history?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// create request
export const updateEmployeeRequestOnLeave = async (update, leaveId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`leave/approval/${update}/${leaveId}`)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

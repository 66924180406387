import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { dataMyRequestOnLeaveHistoryState, fetchDataMyRequestOnLeaveHistory } from '../../store'
import { STORAGE_TOKEN_KEY } from '../../api/constant'
import { useRecoilState } from 'recoil'

export default function useFetchDataMyRequestOnLeaveHistory(setLoading) {
  const [dataMyRequestOnLeaveHistory, setDataMyRequestOnLeaveHistory] = useRecoilState(
    dataMyRequestOnLeaveHistoryState,
  )

  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      setLoading(true)
      const params = 'page=1&size=10'
      fetchDataMyRequestOnLeaveHistory(params, (data, err) => {
        if (!err) {
          setDataMyRequestOnLeaveHistory(data)
          setLoading(false)
          isApiSubscribed = false
        } else {
          if (err.redirect) {
            localStorage.removeItem(STORAGE_TOKEN_KEY)
            history.push('/login')
            setLoading(false)
          }
        }
      })
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [history, setDataMyRequestOnLeaveHistory, setLoading])

  return { dataMyRequestOnLeaveHistory }
}

import React from 'react'
import { Text, StyleSheet } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import Table from './Table'

Allergies.propTypes = {
  data: PropTypes.array,
}

export default function Allergies({ data }) {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    title: {
      fontWeight: 'bold',
      color: '#AF924D',
      marginLeft: 5,
      marginBottom: 10,
    },
    head: {
      display: 'flex',
      flexDirection: 'row',
      padding: 2,
      backgroundColor: '#AF924D',
      color: 'white',
    },
    content: {
      width: '50%',
      margin: 5,
    },
    body: {
      display: 'flex',
      flexDirection: 'row',
      padding: 2,
    },
  })

  const headers = [
    {
      title: 'Name of Medication/Supplement/Food:',
      width: '50%',
      border: true,
    },
    {
      title: 'Reaction:',
      width: '50%',
      border: false,
    },
  ]

  const DEFAULT = {
    medication: { data: '-', width: '50%', border: true },
    reaction: { data: '-', width: '50%', border: false },
  }

  const items = data.map((el) => {
    const obj = {
      medication: { data: '-', width: '50%', border: true },
      reaction: { data: '-', width: '50%', border: false },
    }
    for (const key in el) {
      obj[key].data = el[key]
    }
    return obj
  })

  if (items.length <= 5) {
    const length = 5 - items.length
    for (let i = 0; i < length; i++) {
      items.push(DEFAULT)
    }
  }

  return (
    <>
      <Text style={{ ...styles.title, fontSize: 14, marginTop: 10, marginBottom: 10 }}>
        Allergies
      </Text>
      <Table headers={headers} items={items} />
    </>
  )
}

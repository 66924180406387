import React, { useEffect, useState } from 'react'
import { CButton, CButtonGroup } from '@coreui/react'

import PropTypes from 'prop-types'

import { HiChevronRight, HiChevronLeft } from 'react-icons/hi'
import { IconContext } from 'react-icons'

const PaginationButton = ({ page, setPage, meta, setRefetch }) => {
  PaginationButton.propTypes = {
    page: PropTypes.number,
    setPage: PropTypes.func,
    setRefetch: PropTypes.func,
    meta: PropTypes.object,
  }

  const [pageNumbers, setPageNumbers] = useState([])
  useEffect(() => {
    let numbers = []
    const pagePerNumbers = page / 5
    const modPageNumber = page % 5
    const pageNumberCounter = (page - modPageNumber) / 5
    const startPage = modPageNumber ? 5 * pageNumberCounter : 5 * (pageNumberCounter - 1)
    if (pagePerNumbers > 1) {
      for (let i = 1; i <= 5; i++) {
        const numberOfPage = startPage + 1
        if (numberOfPage <= meta?.last_page) {
          if (startPage + i <= meta?.last_page) numbers.push(startPage + i)
        }
      }
    } else {
      for (let i = 1; i <= 5; i++) {
        if (i <= meta?.last_page) {
          numbers.push(i)
        }
      }
    }
    setPageNumbers(numbers)
  }, [page, meta])

  const btnStyle = {
    borderColor: '#CCCCCC',
    color: '#2C384AF2',
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
  return (
    <CButtonGroup
      role="group"
      aria-label="Basic outlined example"
      style={{ backgroundColor: 'white' }}
    >
      <CButton
        color="primary"
        variant="outline"
        style={btnStyle}
        onClick={() => {
          if (page !== 1) {
            const pageNow = page - 1
            setPage(pageNow)
            setRefetch(true)
          }
        }}
      >
        <IconContext.Provider value={{ size: '1.3em' }}>
          <div>
            <HiChevronLeft />
          </div>
        </IconContext.Provider>
      </CButton>
      {pageNumbers.map((el, i) => {
        return (
          <CButton
            key={i}
            color="primary"
            variant="outline"
            active={page === el ? true : false}
            style={btnStyle}
            onClick={() => {
              setPage(el)
              setRefetch(true)
            }}
          >
            {el}
          </CButton>
        )
      })}

      <CButton
        color="primary"
        variant="outline"
        style={btnStyle}
        onClick={() => {
          if (meta?.last_page > page) {
            setPage(page + 1)
            setRefetch(true)
          }
        }}
      >
        <IconContext.Provider value={{ size: '1.3em' }}>
          <div>
            <HiChevronRight />
          </div>
        </IconContext.Provider>
      </CButton>
    </CButtonGroup>
  )
}

export default PaginationButton

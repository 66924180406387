import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { STORAGE_TOKEN_KEY } from '../../api/constant'

export default function useFetchCMSContentPagination(
  fetchFuntion,
  contentType,
  setRecoilData,
  page,
  setLoading,
) {
  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      setLoading(true)
      const params = `page=${page}&size=10&orderBy=createdAt&orderDir=asc&search`

      fetchFuntion(contentType, params, (data, err) => {
        if (data) {
          setRecoilData(data)
          setLoading(false)
          isApiSubscribed = false
        } else {
          if (err.redirect) {
            localStorage.removeItem(STORAGE_TOKEN_KEY)
            history.push('/login')
            setLoading(false)
            isApiSubscribed = false
          }
        }
      })
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [setRecoilData, history, setLoading, page, fetchFuntion, contentType])
}

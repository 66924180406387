import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch feedbacks
export const dataPromotionListState = atom({
  key: 'dataPromotionListState',
  default: { rows: [], meta: {} },
})

export const dataPromotionState = atom({
  key: 'dataPromotionState',
  default: {},
})

export const fetchDataPromotionsAll = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`promotions/all?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataPromotion = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`promotions/${id}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const createDataPromotion = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('promotions', input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const updateDataPromotion = async (id, input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`promotions/${id}`, input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const deleteDataPromotion = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete(`promotions/${id}`)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { fetchNotification } from '../../store'
import { STORAGE_TOKEN_KEY } from '../../api/constant'

export default function useFetchNotifications(page = '1', size = '10') {
  const history = useHistory()

  const [dataNotification, setDataNotification] = useState({ rows: [], meta: {} })
  const [notRead, setNotRead] = useState(false)

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      fetchNotification(`size=${size}&page=${page}`, (data, err) => {
        isApiSubscribed = false

        if (err) {
          if (err.redirect) {
            localStorage.removeItem(STORAGE_TOKEN_KEY)
            history.push('/login')
          }
          isApiSubscribed = false
        } else {
          setDataNotification(data)
          // untuk check apakah ada notif yang belum dibaca
          if (data.rows.length) {
            data.rows.forEach((el) => {
              if (!el.readAt) {
                setNotRead(true)
              }
            })
          }
        }
      })
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
      setDataNotification({ rows: [], meta: {} })
      setNotRead(false)
    }
  }, [history, setDataNotification, page, size])

  return { dataNotification, setDataNotification, notRead }
}

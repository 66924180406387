import { CToaster } from '@coreui/react'
import React, { useRef } from 'react'
import PropTypes from 'prop-types'

const Toast = ({ toast }) => {
  Toast.propTypes = {
    toast: PropTypes.object,
  }

  const toaster = useRef()

  return (
    <>
      <CToaster ref={toaster} push={toast} placement="top-end" />
    </>
  )
}

export default Toast

import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch patients
export const dataPatientState = atom({
  key: 'dataPatientState',
  default: { rows: [], meta: {} },
})
export const dataActivePatientState = atom({
  key: 'dataActivePatientState',
  default: { rows: [], meta: {} },
})
export const dataInactivePatientState = atom({
  key: 'dataInactivePatientState',
  default: { rows: [], meta: {} },
})
export const dataUnverifiedPatientState = atom({
  key: 'dataUnverifiedPatientState',
  default: { rows: [], meta: {} },
})
export const dataNewPatientState = atom({
  key: 'dataNewPatientState',
  default: { rows: [], meta: {} },
})

export const dataCanEnter = atom({
  key: 'canEnter',
  default: { status: false, data: {} },
})

export const dataCanEnterPrescription = atom({
  key: 'canEnterPrescription',
  default: { status: false, data: {} },
})

export const dataPatientMobileState = atom({
  key: 'dataPatientMobileState',
  default: { rows: [], meta: {} },
})

export const dataPatientListState = atom({
  key: 'dataPatientListState',
  default: [],
})

export const dataDoctorListState = atom({
  key: 'dataDoctorListState',
  default: [],
})

export const dataPatientListActiveInActiveState = atom({
  key: 'dataPatientListActiveInActiveState',
  default: [],
})

export const dataPatientOne = atom({
  key: 'dataPatientOne',
  default: { status: false, data: {} },
})

export const dataPatientOnly = atom({
  key: 'dataPatientOnly',
  default: {},
})

export const fetchPatientOne = async (medicalId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/${medicalId}`)
    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataPatientListActiveInactive = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/list?status=active,inactive`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataPatientList = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/list${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataPatient = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// create patients
export const createPatients = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('patients', input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch one
export const fetchOneDataPatient = async (medicalId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/${medicalId}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// new
export const fetchOneDataPatientNew = async (medicalId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/${medicalId}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

//deactivate
export const deactivatePatients = async (medicalId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`patients/deactivate/${medicalId}`)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

//activate
export const activatePatients = async (medicalId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`patients/activate/${medicalId}`)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// update patient
export const updateDataPatient = async (medicalId, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch('patients/' + medicalId, payload)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// delete patient
export const deleteDataPatient = async (param, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete('patients/' + param)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchpatientMobile = async (param, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/mobile-user?${param}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const dataListProvinceState = atom({
  key: 'dataListProvinceState',
  default: [],
})
export const fetchListProvince = async (param, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/region/provinces`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const dataListCitiesState = atom({
  key: 'dataListCitiesState',
  default: [],
})
export const fetchListCities = async (provinceId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/region/cities/${provinceId}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchCanEnter = async (medicalId, regno, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/can-enter-data/${medicalId}/${regno}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchCanEnterPrescription = async (medicalId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/can-enter-data-prescription/${medicalId}`)
    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
export const fetchCanEnterMR = async (medicalId, regno, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/can-enter-data-mr/${medicalId}/${regno}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const vitalSignFilled = async (medicalId, regno, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/vital-sign-filled/${medicalId}/${regno}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchCanOrderSeraphim = async (regno, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/can-order-seraphim/${regno}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const getPatientByIdentity = async (payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('patients/check-patient', payload)
    if (data.status) {
      cb(data.data, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const selfRegister = async (payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('patients/self-registration', payload)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const selfRegisterState = atom({
  key: 'selfRegisterState',
  default: { rows: [], meta: {} },
})

export const fetchSelfRegisterPagination = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/registration-request?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchOneSelfRegistration = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/registration-request/${id}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const deleteSelfRegistration = async (id, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete(`patients/registration-request/${id}`, { data: payload })
    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const finishRegistration = async (payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`patients/registration-request/complete-request`, payload)
    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      cb(error.response.data.message)
    } else {
      errorResponse(error, cb)
    }
  }
}

export const fetchOneRegistration = async (regno, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`registrations/regno/${regno}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchChartPatientBySource = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/patients/chart/resource?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

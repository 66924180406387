export const errorResponse = (error, cb) => {
  console.log(error, 'errorresponse')
  if (error.response) {
    if (error.response.status === 401) {
      cb(null, { redirect: true })
    } else if (error.response.status === 422) {
      cb(null, { message: error.response.data.errors[0].msg })
    } else if (error.response.data.message) {
      cb(null, { message: error.response.data.message })
    } else {
      alert(error.response.data.message + ' harap refresh website')
      // window.location.reload()
    }
  } else {
    alert(error.message)
    // window.location.reload()
  }
}

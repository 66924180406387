import React from 'react'
import { Text, StyleSheet, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
// import TableComponent from '../components/TableComponent'

FamilyHistory.propTypes = {
  data: PropTypes.array,
}

export default function FamilyHistory({ data }) {
  const borderColor = '#d9da55'
  const styles = StyleSheet.create({
    question: {
      marginLeft: 5,
    },
    tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      borderWidth: 1,
      borderColor: '#d9da55',
    },
    titleContainer: {
      flexDirection: 'row',
      borderBottomColor: '#d9da55',
      backgroundColor: '#afbc21',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 24,
      textAlign: 'center',
      fontStyle: 'bold',
      flexGrow: 1,
    },
    title: {
      color: '#015287',
      fontWeight: 'bold',
      height: '100%',
      paddingTop: 8,
    },
    borderRight: {
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    rowContainer: {
      flexDirection: 'row',
      borderBottomColor: '#d9da55',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 24,
      fontStyle: 'bold',
    },
    row: {
      textAlign: 'center',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
      color: '#015287',
      backgroundColor: '#f9f9ea',
      height: '100%',
    },
    box: {
      width: 10,
      height: 10,
      border: '1px solid black',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 auto',
      fontSize: 7,
    },
    notes: {
      fontWeight: 'bold',
      color: '#AF924D',
      marginLeft: 5,
      marginBottom: 10,
    },
  })

  const CheckboxNoText = (isCheck) => (
    <View style={styles.box}>{isCheck.isCheck ? <Text>x</Text> : null}</View>
  )

  const border = (data) => (data.border ? styles.borderRight : null)

  const headers = [
    {
      title: '',
      width: '35%',
      border: true,
    },
    {
      title: 'M',
      width: '5%',
      border: true,
    },

    {
      title: 'F',
      width: '5%',
      border: true,
    },
    {
      title: 'B',
      width: '5%',
      border: true,
    },
    {
      title: 'S',
      width: '5%',
      border: true,
    },
    {
      title: 'C',
      width: '5%',
      border: true,
    },
    {
      title: 'C',
      width: '5%',
      border: true,
    },
    {
      title: 'C',
      width: '5%',
      border: true,
    },
    {
      title: 'C',
      width: '5%',
      border: true,
    },
    {
      title: 'Mm',
      width: '5%',
      border: true,
    },
    {
      title: 'Mf',
      width: '5%',
      border: true,
    },
    {
      title: 'Pm',
      width: '5%',
      border: true,
    },
    {
      title: 'Pf',
      width: '5%',
      border: true,
    },
    {
      title: 'O',
      width: '5%',
      border: false,
    },
  ]

  const isBoolean = (val) => 'boolean' === typeof val

  const items = JSON.parse(data[0].answer).map((el) => {
    return {
      title: {
        data: {
          type: 'text',
          text: el.fillable ? `${el.question}: ${el.input}` : el.question,
        },
        width: '35%',
        border: true,
      },
      M: {
        data: { type: isBoolean(el.mother) ? 'checkbox' : 'none', check: el.mother },
        width: '5%',
        border: true,
      },
      F: {
        data: { type: isBoolean(el.father) ? 'checkbox' : 'none', check: el.father },
        width: '5%',
        border: true,
      },
      B: {
        data: { type: isBoolean(el.brother) ? 'checkbox' : 'none', check: el.brother },
        width: '5%',
        border: true,
      },
      S: {
        data: { type: isBoolean(el.sister) ? 'checkbox' : 'none', check: el.sister },
        width: '5%',
        border: true,
      },
      C1: {
        data: { type: isBoolean(el.child1) ? 'checkbox' : 'none', check: el.child1 },
        width: '5%',
        border: true,
      },
      C2: {
        data: { type: isBoolean(el.child2) ? 'checkbox' : 'none', check: el.child2 },
        width: '5%',
        border: true,
      },
      C3: {
        data: { type: isBoolean(el.child3) ? 'checkbox' : 'none', check: el.child3 },
        width: '5%',
        border: true,
      },
      C4: {
        data: { type: isBoolean(el.child4) ? 'checkbox' : 'none', check: el.child4 },
        width: '5%',
        border: true,
      },
      Mm: {
        data: { type: isBoolean(el.child4) ? 'checkbox' : 'none', check: el.maternalGrandmother },
        width: '5%',
        border: true,
      },
      Mf: {
        data: { type: isBoolean(el.child4) ? 'checkbox' : 'none', check: el.maternalGrandfather },
        width: '5%',
        border: true,
      },
      Pm: {
        data: { type: isBoolean(el.child4) ? 'checkbox' : 'none', check: el.paternalGrandmother },
        width: '5%',
        border: true,
      },
      Pf: {
        data: { type: isBoolean(el.child4) ? 'checkbox' : 'none', check: el.paternalGrandfather },
        width: '5%',
        border: true,
      },
      O: {
        data: { type: isBoolean(el.child4) ? 'checkbox' : 'none', check: el.other },
        width: '5%',
        border: false,
      },
    }
  })

  return (
    <>
      <Text style={{ ...styles.question, marginBottom: 10 }}>
        Check family members that have/had any of the following
      </Text>
      <Text style={{ ...styles.notes, fontSize: 8, marginBottom: 10, color: 'black' }}>
        Notes: M = Mother, F = Father, B = Brother, S = Sister, C = Child, Mm = Maternal
        Grandmother, Mf = Maternal Grandfather, Pm = Paternal Grandmother, Pf = Paternal
        Grandfather, O = Other
      </Text>

      {/* table */}
      <View style={styles.tableContainer}>
        {/* headers */}
        <View style={styles.titleContainer}>
          {headers.map((head, i) => (
            <Text key={i} style={{ ...styles.title, ...border(head), width: head.width }}>
              {head.title}
            </Text>
          ))}
        </View>
        {/* rows */}
        {items.map((data, j) => {
          const keys = Object.keys(data)
          return (
            <View style={styles.rowContainer} key={j}>
              {keys.map((el, k) => (
                <View key={k} style={{ ...styles.row, width: data[el].width, ...border(data[el]) }}>
                  {data[el].data.type === 'text' ? (
                    <Text>{data[el].data.text}</Text>
                  ) : data[el].data.type === 'checkbox' ? (
                    <CheckboxNoText isCheck={data[el].data.check} />
                  ) : data[el].data.type === 'none' ? (
                    <View></View>
                  ) : null}
                </View>
              ))}
            </View>
          )
        })}
      </View>
    </>
  )
}

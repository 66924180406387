import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch loyalti management
export const showLoyaltiManagement = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`loyalties/management`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// update loyalti management
export const updateLoyaltiManagement = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch('loyalties/management', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// patient list
export const loyaltiPatientListState = atom({
  key: 'loyaltiPatientListState',
  default: { rows: [], meta: {} },
})
export const fetchLoyaltiPatientList = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`loyalties/patientspoint?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// patient detail
export const loyaltiPatientDetailState = atom({
  key: 'loyaltiPatientDetailState',
  default: { rows: [], meta: {} },
})
export const fetchLoyaltiPatientListDetail = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`loyalties/patientspointlog/${id}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// item management
export const loyaltiItemState = atom({
  key: 'loyaltiItemState',
  default: { rows: [], meta: {} },
})
export const fetchLoyaltiItems = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`loyalties/items?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// loyalti category
export const loyaltyCategoryState = atom({
  key: 'loyaltyCategoryState',
  default: [],
})
export const fetchLoyaltyCategory = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`loyalties/categories`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// create loyalties/item
export const createLoyaltyItem = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('loyalties/item', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const deleteLoyaltyItem = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete(`loyalties/item/${id}`)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const editLoyaltyItem = async (id, input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`loyalties/item/${id}`, input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const showLoyaltyItem = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`loyalties/item/${id}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

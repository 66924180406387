import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch data profile
export const dataProfileState = atom({
  key: 'dataProfileState',
  default: {},
})
export const fetchDataProfile = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('profile')

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// update profile
export const updateDataProfile = async (payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch('profile', payload)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const updateEmailProfile = async (payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('profile/change-email', payload)

    if (data.status) {
      cb(data.message)
    } else {
      cb(null, data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const updatePasswordProfile = async (payload, cb) => {
  try {
    const { request } = API(true)
    if (payload.password === payload.password_confirmation) {
      const { data } = await request.post('profile/change-password', payload)
      if (data.status) {
        cb(data.message)
      }
    } else {
      const error = {
        message: 'Password is not same with confirm password, please check again your input',
      }
      cb(null, error)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

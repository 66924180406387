import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch data Appointment all
export const dataContentAboutAllState = atom({
  key: 'dataContentAboutAllState',
  default: { rows: [], meta: {} },
})

export const dataContentAboutState = atom({
  key: 'dataContentAboutState',
  default: {},
})

export const dataContentFAQAllState = atom({
  key: 'dataContentFAQAllState',
  default: { rows: [], meta: {} },
})

export const dataContentFAQState = atom({
  key: 'dataContentFAQState',
  default: {},
})

export const dataContentTNCAllState = atom({
  key: 'dataContentTNCAllState',
  default: { rows: [], meta: {} },
})

export const dataContentTNCState = atom({
  key: 'dataContentTNCState',
  default: {},
})

export const dataContentPrivacyPolicyAllState = atom({
  key: 'dataContentPrivacyPolicyAllState',
  default: { rows: [], meta: {} },
})

export const dataContentPrivacyPolicyState = atom({
  key: 'dataContentPrivacyPolicyState',
  default: {},
})

export const fetchCMSContentPagination = async (contentType, params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`${contentType}?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchCMSContentNoParam = async (contentType, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`${contentType}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchCMSContentOne = async (contentType, id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`${contentType}/${id}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const updateCMSContent = async (contentType, id, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`${contentType}/${id}`, payload)
    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const updateCMSContenNoId = async (contentType, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`${contentType}`, payload)
    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const updateCMSContentChangeStatus = async (contentType, id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`${contentType}/${id}/changestatus`)
    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// create data Appointment
export const createCMSContent = async (contentType, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`${contentType}`, payload)

    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else if (error.response.status === 422) {
        cb(null, { message: error.response.data.errors[0].msg })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const deleteCMSContent = async (contentType, id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete(`${contentType}/${id}`)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch our doctor
export const ourDoctorPaginationState = atom({
  key: 'ourDoctorPaginationState',
  default: { rows: [], meta: {} },
})
export const fetchOurDoctorPagination = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`our-doctors?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

//delete our doctor
export const deleteOurDoctor = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete(`our-doctors/${id}`)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
// create our doctor
export const createOurDoctor = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('our-doctors', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch list doctor
export const dataDoctorListCMSState = atom({
  key: 'dataDoctorListCMSState',
  default: [],
})

export const fetchDataDoctorList = async (params, cb) => {
  // = 'status=active'
  try {
    const { request } = API(true)
    const { data } = await request.get(`officers/doctor-list?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch one our doctor
export const fetchOurDoctorOne = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`our-doctors/${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

//update our doctor
export const updateOurDoctor = async (id, input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`our-doctors/${id}`, input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

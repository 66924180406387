import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useRecoilState } from 'recoil'
import { STORAGE_TOKEN_KEY } from '../api/constant'
import { dataScheduleState, fetchDataSchedule } from '../store'

export default function useFetchDataSchedule(setLoading, date, doctorId, branch) {
  const [dataSchedule, setDataSchedule] = useRecoilState(dataScheduleState)

  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      setLoading(true)
      const params = `date=${date}&branch=${branch}&doctorId=${doctorId}`

      fetchDataSchedule(params, (data, err) => {
        if (data) {
          setDataSchedule(data)
          setLoading(false)
          if (!data.length) {
            isApiSubscribed = false
          }
        } else {
          if (err.redirect) {
            localStorage.removeItem(STORAGE_TOKEN_KEY)
            history.push('/login')
            setLoading(false)
          }
        }
      })
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [setDataSchedule, history, date, doctorId, branch, setLoading])

  return { dataSchedule }
}

import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

export const checkIP = async (cb) => {
  try {
    const { request } = API(false)
    const { data } = await request.get(`whitelist/check`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

import axios from 'axios'
import * as constants from './constant'

let ENVIRONMENT = 'local'
console.log(process.env.REACT_APP_ENV, 'REACT_APP_ENV')
if (process.env.REACT_APP_ENV === 'production') {
  ENVIRONMENT = 'production'
} else if (process.env.REACT_APP_ENV === 'staging') {
  ENVIRONMENT = 'staging'
}

export const BASE_URL =
  ENVIRONMENT === 'production'
    ? constants.BASE_URL_PRODUCTION
    : ENVIRONMENT === 'staging'
    ? constants.BASE_URL_STAGING
    : constants.BASE_URL_LOCAL
/**
 *
 * @param authenticated true | false
 * @param token string JWT
 * @param isMultipart for file upload
 * @returns
 */
export const API = (authenticated = true, token, isMultipart = false) => {
  const requestHeaders = {
    accept: 'application/json',
  }

  if (authenticated) {
    if (token) {
      requestHeaders.Authorization = 'Bearer ' + token
    } else {
      const tokenOnDevice = getTokenFromDevice()
      if (tokenOnDevice !== null) {
        requestHeaders.Authorization = 'Bearer ' + tokenOnDevice
      }
    }
  }

  if (isMultipart) {
    requestHeaders['Content-Type'] = 'multipart/form-data'
  }

  const cancelSource = axios.CancelToken.source()
  const request = axios.create({
    baseURL: BASE_URL,
    timeout: 60000, // 1 menute to cancel request
    headers: requestHeaders,
    cancelToken: cancelSource.token,
  })

  return { request, cancelSource }
}

export const getTokenFromDevice = () => {
  return localStorage.getItem(constants.STORAGE_TOKEN_KEY)
}

import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { dataMasterTreatmentState, fetchDataMasterTreatments } from '../../store'
import { STORAGE_TOKEN_KEY } from '../../api/constant'
import { useRecoilState } from 'recoil'

export default function useFetchDataMasterTreatment(page, size, setLoading) {
  const [dataMasterTreatment, setDataMasterTreatment] = useRecoilState(dataMasterTreatmentState)
  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      setLoading(true)
      const params = `page=${page}&size=${size}&orderBy=createdAt&orderDir=desc&search`

      fetchDataMasterTreatments(params, (data, err) => {
        if (data) {
          setDataMasterTreatment(data)
          setLoading(false)
          isApiSubscribed = false
        } else {
          if (err.redirect) {
            localStorage.removeItem(STORAGE_TOKEN_KEY)
            history.push('/login')
            setLoading(false)
            isApiSubscribed = false
          }
        }
      })
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [setDataMasterTreatment, history, setLoading, page, size])

  return { dataMasterTreatment, setDataMasterTreatment }
}

import { View, StyleSheet, Text } from '@react-pdf/renderer'
import React from 'react'
// import LabelAndText from './components/LabelAndText'
import PropTypes from 'prop-types'

HealthGoals.propTypes = {
  data: PropTypes.array,
}

export default function HealthGoals({ data }) {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      marginBottom: 10,
      marginTop: 10,
    },
    fontBold: {
      fontWeight: 'bold',
      color: '#AF924D',
      marginRight: 5,
    },
  })

  return (
    <View style={styles.container}>
      {data.map((el) => (
        <View
          key={el.id}
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 10,
            alignItems: 'flex-end',
          }}
        >
          <Text>{el.question}</Text>
          <Text style={{ borderBottom: '1px solid black', flex: 1, paddingBottom: 5 }}>
            {el.answer}
          </Text>
        </View>
      ))}
      {/* <LabelAndText label="When was the last time you felt well?" text="sdsdsd" />
        // <LabelAndText key={el.id} label={el.question} text={el.answer} />
      <LabelAndText label="Did something trigger your change in health?" text="sdsdsd" />
      <LabelAndText label="What makes you feelbetter?" text="sdsdsd" />
      <LabelAndText label="What makes you feel worse?" text="sdsdsd" />
      <LabelAndText label="How does your condition affect you?" text="sdsdsd" />
      <LabelAndText label="What do you think is happening and why?" text="sdsdsd" />
      <LabelAndText label="What do you feel needs to happen for you to get better?" text="sdsdsd" /> */}
    </View>
  )
}

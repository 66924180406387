import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer'
import PropTypes from 'prop-types'

Checkbox.propTypes = {
  text: PropTypes.string,
  isCheck: PropTypes.bool,
  style: PropTypes.object,
}

export default function Checkbox({ isCheck, text, style }) {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    box: {
      width: 10,
      height: 10,
      border: '1px solid black',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 5,
      fontSize: 7,
    },
  })
  return (
    <View style={{ ...styles.container, ...style }}>
      <View style={styles.box}>{isCheck ? <Text>x</Text> : null}</View>
      <Text>{text}</Text>
    </View>
  )
}

import React from 'react'
import { Text, StyleSheet } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import Sleep from './Sleep'
import Exercise from './Exercise'
import Nutrition from './Nutrition'
import Diet from './Diet'
import Smoking from './Smoking'
import Alcohol from './Alcohol'
import OtherSubstances from './OtherSubstances'
import Stress from './Stress'
import Relationships from './Relationships'

LivestyleReview.propTypes = {
  data: PropTypes.object,
}

export default function LivestyleReview({ data: { SLP, EXC, NTT, DET, SMK, ACH, OST, STS, RTS } }) {
  const styles = StyleSheet.create({
    title: {
      fontWeight: 'bold',
      color: '#AF924D',
      marginLeft: 5,
      marginBottom: 10,
      marginTop: 10,
    },
  })

  return (
    <>
      <Text style={{ ...styles.title, fontSize: 14, marginTop: 20 }}>Lifestyle Review</Text>
      <Text style={{ ...styles.title, fontSize: 12 }}>Sleep</Text>
      <Sleep data={SLP} />
      <Text style={{ ...styles.title, fontSize: 12 }}>Exercise</Text>
      <Exercise data={EXC} />
      <Text style={{ ...styles.title, fontSize: 12 }}>Nutrition</Text>
      <Nutrition data={NTT} />
      <Text style={{ ...styles.title, fontSize: 12 }}>Diet</Text>
      <Diet data={DET} />
      <Text style={{ ...styles.title, fontSize: 12 }}>Smoking</Text>
      <Smoking data={SMK} />
      <Text style={{ ...styles.title, fontSize: 12 }}>Alcohol</Text>
      <Alcohol data={ACH} />
      <Text style={{ ...styles.title, fontSize: 12, marginTop: 60 }}>Other Substances</Text>
      <OtherSubstances data={OST} />
      <Text style={{ ...styles.title, fontSize: 12 }}>Stress</Text>
      <Stress data={STS} />
      <Text style={{ ...styles.title, fontSize: 12 }}>Relationships</Text>
      <Relationships data={RTS} />
    </>
  )
}

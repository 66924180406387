import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'
export const fetchInstructionByMedicalID = async (medicalId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`instructions/${medicalId}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const createInstruction = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('instructions', input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { dataMyRequestOnLeaveState, fetchDataMyRequestOnLeaveState } from '../../store'
import { STORAGE_TOKEN_KEY } from '../../api/constant'
import { useRecoilState } from 'recoil'

export default function useFetchDataMyRequestOnLeave(setLoading) {
  const [dataMyRequestOnLeave, setDataMyRequestOnLeave] = useRecoilState(dataMyRequestOnLeaveState)

  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      setLoading(true)
      fetchDataMyRequestOnLeaveState((data, err) => {
        if (!err) {
          setDataMyRequestOnLeave(data)
          setLoading(false)
          isApiSubscribed = false
        } else {
          if (err.redirect) {
            localStorage.removeItem(STORAGE_TOKEN_KEY)
            history.push('/login')
            setLoading(false)
          }
        }
      })
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [setLoading, setDataMyRequestOnLeave, history])

  return { dataMyRequestOnLeave }
}

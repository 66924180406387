import React from 'react'
import PropTypes from 'prop-types'

import { CButton, CModal, CModalBody, CModalHeader, CModalTitle, CSpinner } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilX } from '@coreui/icons'

const ModalDelete = ({
  visibleModalDelete,
  setVisibleModalDelete,
  moduleName,
  functionDelete,
  loadingDelete,
}) => {
  ModalDelete.propTypes = {
    visibleModalDelete: PropTypes.bool,
    setVisibleModalDelete: PropTypes.func,
    moduleName: PropTypes.string,
    functionDelete: PropTypes.func,
    loadingDelete: PropTypes.bool,
  }

  return (
    <>
      <CModal
        alignment="center"
        visible={visibleModalDelete}
        onClose={() => setVisibleModalDelete(false)}
        backdrop={'static'}
      >
        <CModalHeader>
          <CModalTitle>Delete {moduleName}</CModalTitle>
        </CModalHeader>
        <CModalBody className="text-center">
          <CIcon
            icon={cilX}
            alt="Logo"
            style={{
              border: '4px solid red',
              borderRadius: '50%',
              padding: '10px',
              width: '6vw',
              height: '6vw',
              color: 'red',
              marginBottom: '16px',
            }}
          />
          <h2 style={{ marginBottom: '16px' }}>Are you sure?</h2>
          <p>
            Do you really want to delete these {moduleName.toLowerCase()}? <br /> This process
            cannot be undone.
          </p>

          <CButton
            style={{ marginRight: '16px', marginTop: '16px', width: '10vw' }}
            color="secondary"
            onClick={() => setVisibleModalDelete(false)}
          >
            Cancel
          </CButton>

          {!loadingDelete && (
            <CButton
              color="danger"
              style={{ marginTop: '16px', width: '10vw' }}
              onClick={() => functionDelete()}
            >
              Delete
            </CButton>
          )}

          {loadingDelete && (
            <CButton color="danger" style={{ marginTop: '16px', width: '10vw' }} disabled>
              <CSpinner component="span" size="sm" aria-hidden="true" className="me-3" />
              Loading...
            </CButton>
          )}
          <div style={{ height: '40px' }}></div>
        </CModalBody>
      </CModal>
    </>
  )
}

export default ModalDelete

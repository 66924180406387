import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useRecoilState } from 'recoil'
import { STORAGE_TOKEN_KEY } from '../../api/constant'
import { dataLabSampleState, fetchLabSample } from '../../store'

export default function useFetchLabSample(
  setLoading,
  page = 1,
  size = 10,
  isRefetch = false,
  setRefetch = () => {},
) {
  const [dataLabSample, setDataLabSample] = useRecoilState(dataLabSampleState)

  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      if (!Object.keys(dataLabSample.meta).length || isRefetch) {
        setLoading(true)
        fetchLabSample(`page=${page}&size=${size}`, (data, err) => {
          if (data) {
            setDataLabSample(data)
            setLoading(false)
          } else {
            if (err.redirect) {
              localStorage.removeItem(STORAGE_TOKEN_KEY)
              history.push('/login')
              setLoading(false)
            }
          }
        })
      }
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
      setRefetch(false)
    }
  }, [dataLabSample, setDataLabSample, history, setLoading, page, size, isRefetch, setRefetch])

  return { dataLabSample, setDataLabSample, fetchLabSample }
}

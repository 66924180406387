import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch data prescriptions all
export const dataPrescriptionState = atom({
  key: 'dataPrescriptionState',
  default: { rows: [], meta: {} },
})

// fetch data prescriptions external all
export const dataPrescriptionExternalState = atom({
  key: 'dataPrescriptionExternalState',
  default: { rows: [], meta: {} },
})

// fetch data prescriptions all
export const dataMasterTreatmentState = atom({
  key: 'dataMasterTreatmentState',
  default: { rows: [], meta: {} },
})

export const dataMasterItemState = atom({
  key: 'dataMasterItemState',
  default: { rows: [], meta: {} },
})

export const dataMedicineListState = atom({
  key: 'dataMedicineListState',
  default: [],
})

export const fetchDataMasterTreatments = async (param, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`treatments?${param}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const fetchDataMasterItems = async (param, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`products/medicines?${param}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const fetchUpdatetHis = async (updateType, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`his/${updateType}`)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const fetchDataMedicineList = async (branch, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`products/medicines/list?branch=${branch}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const fetchDataPrescriptionAll = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`prescriptions?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const fetchDataPrescriptionPharmacy = async (param, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`prescriptions/pharmacy?${param}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}
export const fetchDataPrescriptionAllPaid = async (param, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`prescriptions/paid?${param}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const fetchDataPrescriptionExternalAllPaid = async (param, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`prescriptions/external/paid?${param}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch data prescriptions all
export const dataPharmacyStockState = atom({
  key: 'dataPharmacyStockState',
  default: { rows: [], meta: {} },
})

export const fetchDataPharmacyStockAll = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`products/medicines?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch data prescriptions show
export const dataPrescriptionShowState = atom({
  key: 'dataPrescriptionShowState',
  default: {},
})

export const dataPrescriptionExternalShowState = atom({
  key: 'dataPrescriptionExternalShowState',
  default: {},
})

export const fetchDataPrescriptionShow = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`prescriptions/${id}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const fetchDataPrescriptionExternalShow = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`prescriptions/external/${id}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const createDataPrescription = async (payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`prescriptions/`, payload)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const deleteDataPrescription = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete(`prescriptions/${id}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const updateDataPrescription = async (id, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`prescriptions/${id}`, payload)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const updateSetSuccessStatusPayment = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`prescriptions/${id}/set-success`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const confirmDataPrescription = async (id, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`prescriptions/${id}/confirm`, payload)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch one prescription item
export const fetchPrescriptionItemOne = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`prescriptions/item/${id}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchPrescriptionExternalItemOne = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`prescriptions/external/item/${id}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

//update prescription item expired
export const updateprescriptionItemExpired = async (id, input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`prescriptions/${id}/updateexpired`, input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const updateprescriptionExternalItemExpired = async (id, input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`prescriptions/external/${id}/updateexpired`, input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchGeneralDataPrescription = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('prescriptions/general-data-prescriprion')
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer'
import PropTypes from 'prop-types'

LabelAndText.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
}

export default function LabelAndText({ label, text, style }) {
  const styles = StyleSheet.create({
    label: {
      display: 'flex',
      flexDirection: 'row',
      width: '45%',
      margin: 5,
      alignItems: 'flex-end',
    },
    name: {
      width: '35%',
    },
    underScore: {
      borderBottom: '1px solid black',
      flex: 1,
      padding: 0,
      paddingLeft: 5,
      marginLeft: 10,
      paddingBottom: 3,
    },
  })
  return (
    <View style={{ ...styles.label, ...style }}>
      {label !== 'none' && <Text style={styles.name}>{label} :</Text>}
      <Text style={styles.underScore}>{text}</Text>
    </View>
  )
}

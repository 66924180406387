import { View, StyleSheet, Text } from '@react-pdf/renderer'
import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '../components/Checkbox'

Index.propTypes = {
  data: PropTypes.array,
}

export default function Index({ data }) {
  const styles = StyleSheet.create({
    title: {
      fontWeight: 'bold',
      color: '#AF924D',
      marginLeft: 5,
      marginBottom: 10,
    },
    question: {
      marginLeft: 5,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      // marginTop: 10,
    },
    label: {
      display: 'flex',
      flexDirection: 'row',
      margin: 5,
    },
    underScore: {
      borderBottom: '1px solid black',
      flex: 1,
      padding: 0,
      paddingLeft: 5,
      marginLeft: 10,
    },
  })

  return (
    <>
      {data.map((el) => (
        <React.Fragment key={el.id}>
          {el.type === 'checkbox' ? (
            <>
              <View style={styles.container}>
                <Text style={styles.question}>{el.question}</Text>
                {JSON.parse(el.answer).map((check) => (
                  <React.Fragment key={check.code}>
                    {check.fillabel ? (
                      <View style={styles.container}>
                        <Checkbox
                          isCheck={check.checked}
                          text={check.value}
                          style={{ margin: 5 }}
                        />
                        <Text style={styles.underScore}>{check.answer}</Text>
                      </View>
                    ) : (
                      <Checkbox isCheck={check.checked} text={check.value} style={{ margin: 5 }} />
                    )}
                  </React.Fragment>
                ))}
              </View>
            </>
          ) : el.type === 'radio' ? (
            <>
              <Text style={{ fontWeight: 'bold', fontSize: 12, marginBottom: 4 }}>
                {el.h3_desc}
              </Text>

              <View style={styles.container}>
                <Text style={styles.question}>{el.question}</Text>
                {JSON.parse(el.answer).map((check) => (
                  <Checkbox
                    key={check.code}
                    isCheck={check.selected}
                    text={check.value}
                    style={{ margin: 5 }}
                  />
                ))}
              </View>
            </>
          ) : el.type === 'text' ? (
            <View style={styles.label}>
              <Text>{el.question}</Text>
              <Text style={styles.underScore}>{el.answer}</Text>
            </View>
          ) : el.type === 'questions_arr' ? (
            <>
              <Text style={{ ...styles.question, marginBottom: 10 }}>{el.question}</Text>
              {JSON.parse(el.answer).map((check) => (
                <View key={check.code} style={styles.label}>
                  <Text>{check.question}</Text>
                  <Text style={styles.underScore}>{check.answer}</Text>
                </View>
              ))}
            </>
          ) : el.type === 'no_answer' ? (
            <>
              <Text style={{ ...styles.title, fontSize: 14, marginTop: 10 }}>{el.h1_desc}</Text>
              <Text style={{ ...styles.title, fontSize: 12, marginTop: 8 }}>{el.h2_desc}</Text>
              <Text
                style={{
                  ...styles.title,
                  fontSize: 12,
                  marginTop: 8,
                  color: 'black',
                }}
              >
                {el.h3_desc}
              </Text>
              <Text
                style={{
                  marginTop: 8,
                  marginBottom: 4,
                  color: 'black',
                }}
              >
                {el.question}
              </Text>
            </>
          ) : null}
        </React.Fragment>
      ))}
      {/* <View style={styles.container}>
       <Text style={{ ...styles.fontBold, marginBottom: 10 }}>
         Readiness Assessment and Health Goals
       </Text>
       <Text style={{ ...styles.fontBold, marginBottom: 10 }}>Readiness Assessment</Text>
       <View style={styles.containerView}>
         <Text style={styles.textBold}>Rate on a scale of 5 (very willing) to 1 (not willing):</Text>
         <Text>In order to improve your health, how willing are you to:</Text>
         <QuestionWithCheckbox question={'Take several nutritional supplements each day'} />
         <QuestionWithCheckbox question={'Keep a record of everything you eat each day'} />
         <QuestionWithCheckbox
           question={'Modify your lifestyle (e.g., work demands, sleep habits)'}
         />
         <QuestionWithCheckbox question={'Practice a relaxation technique'} />
         <QuestionWithCheckbox question={'Engage in regular exercise'} />
       </View>
       <View style={styles.containerView}>
         <Text style={styles.textBold}>
           Rate on a scale of 5 (very confident) to 1 (not confident at all):
         </Text>
         <QuestionWithCheckbox
           question={
             'How confident are you of your ability to organize and follow through on the above health-related activities?'
           }
         />
         <QuestionWithUnderline
           question={
             'If you are not confident of your ability, what aspects of yourself or your life lead you to question your capacity to follow through?'
           }
         />
       </View>

       <View style={styles.containerView}>
         <Text style={styles.textBold}>
           Rate on a scale of 5 (very supportive) to 1 (very unsupportive):
         </Text>
         <QuestionWithCheckbox
           question={
             'At the present time, how supportive do you think the people in your household will be to your implementing the above changes?'
           }
         />
       </View>

       <View style={styles.containerView}>
         <Text style={styles.textBold}>
           Rate on a scale of 5 (very frequent contact) to 1 (very infrequent contact):
         </Text>
         <QuestionWithCheckbox
           question={
             'How much ongoing support (e.g., telephone consults, email correspondence) from our professional staff would be helpful to you as you implement your personal health program?'
           }
         />
         <QuestionWithUnderline question={'Comments'} />
       </View>
     </View> */}
    </>
  )
}

import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch registration
export const dataRegistrationState = atom({
  key: 'dataRegistrationState',
  default: { rows: [], meta: {} },
})

export const fetchDataRegistrationByPractitioner = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(
      `registrations/registered-patients/by-practitioner?${params}`,
    )
    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataRegistration = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`registrations?${params}`)
    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const cancelRegistartion = async (regno, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`registrations/cancel/${regno}`, payload)

    cb(data)
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        cb(error.response.data)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const checkOpenRegistration = async (payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`registrations/check-registration/`, payload)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

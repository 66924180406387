import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { STORAGE_TOKEN_KEY } from '../api/constant'
import { fetchDataAppointmentTreatmentList } from '../store'

export default function useFetchDataTreatmentList(
  setLoading,
  chargeParent = '',
  indexFetch = 0,
  branch = '',
) {
  const [treatmentList, setTreatmentList] = useState([])

  const history = useHistory()
  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      if (typeof chargeParent === 'string') {
        if (chargeParent !== '') {
          fetchDataAppointmentTreatmentList(branch, chargeParent, (data, err) => {
            if (data) {
              const listTemp = treatmentList
              listTemp[indexFetch] = data
              setTreatmentList([...listTemp])
              if (!data.length) {
                isApiSubscribed = false
              }
            } else {
              if (err.redirect) {
                localStorage.removeItem(STORAGE_TOKEN_KEY)
                history.push('/login')
              }
            }
          })
        }
      } else {
        if (chargeParent !== null) {
          if (chargeParent.length > 0) {
            if (chargeParent[indexFetch] !== '05') {
              fetchDataAppointmentTreatmentList(branch, chargeParent[indexFetch], (data, err) => {
                if (data) {
                  const listTemp = treatmentList
                  listTemp[indexFetch] = data
                  setTreatmentList([...listTemp])
                  if (!data.length) {
                    isApiSubscribed = false
                  }
                } else {
                  if (err.redirect) {
                    localStorage.removeItem(STORAGE_TOKEN_KEY)
                    history.push('/login')
                  }
                }
              })
            } else {
              const listTemp = treatmentList
              listTemp[indexFetch] = []
              setTreatmentList([...listTemp])
            }
          }
        }
      }
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading, history, indexFetch, chargeParent])

  return { treatmentList, setTreatmentList }
}

// fetchDataMedicineList(branch, (data, err) => {
//   if (data) {
//     const listTemp = treatmentList
//     listTemp[indexFetch] = data
//     setTreatmentList([...listTemp])
//     if (!data.length) {
//       isApiSubscribed = false
//     }
//   } else {
//     if (err.redirect) {
//       localStorage.removeItem(STORAGE_TOKEN_KEY)
//       history.push('/login')
//     }
//   }
// })

import { useEffect } from 'react'
import { useHistory } from 'react-router'

import { STORAGE_TOKEN_KEY } from '../../api/constant'

export default function useFetchCount(fetchData, param, data, setData, setLoading, nested = true) {
  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      setLoading(true)
      fetchData(param, (_data, err) => {
        if (_data.status) {
          if (!nested) {
            setData(_data)
          } else {
            setData(_data.data)
          }
          setLoading(false)
          isApiSubscribed = false
        } else {
          if (err) {
            if (err.redirect) {
              localStorage.removeItem(STORAGE_TOKEN_KEY)
              history.push('/login')
              setLoading(false)
            }
          }
        }
      })
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [fetchData, history, param, setLoading, setData, nested])

  return { data, setData }
}

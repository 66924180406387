import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

export const dataNotificationState = atom({
  key: 'dataNotificationState',
  default: { rows: [], meta: {} },
})

export const fetchNotification = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`notification/pagination?readStatus=all&${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchNotificationById = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`notification/${id}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const setFirebaseToken = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('auth/setwebtoken', input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const deleteNotification = async (param, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete('notification/' + param)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { STORAGE_TOKEN_KEY } from '../api/constant'
import { fetchDataAppointmentCategoryList } from '../store'

export default function useFetchDataCategory(setLoading, branch, indexFetch) {
  const [dataCategory, setDataCategory] = useState([])

  const history = useHistory()
  /// solve masalah category list nya
  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      if (branch !== '') {
        fetchDataAppointmentCategoryList(branch, (data, err) => {
          if (data) {
            const listTemp = dataCategory
            listTemp[indexFetch] = data
            setDataCategory([...listTemp])
            if (!data.length) {
              isApiSubscribed = false
            }
          } else {
            if (err.redirect) {
              localStorage.removeItem(STORAGE_TOKEN_KEY)
              history.push('/login')
              setLoading(false)
            }
          }
        })
      }
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch, history, indexFetch, setLoading])

  return { dataCategory, setDataCategory }
}

import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { fetchFMTreatmentRecord } from '../../store'
import { STORAGE_TOKEN_KEY } from '../../api/constant'

export default function useFetchDataFM(medicalID, url, setLoading, setRecoil) {
  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      setLoading(true)
      const params = `page=1&size=3&orderBy=createdAt&orderDir=desc&search`

      fetchFMTreatmentRecord(url, params, medicalID, (data, err) => {
        if (data) {
          setRecoil(data)
          setLoading(false)
          isApiSubscribed = false
        } else {
          if (err.redirect) {
            localStorage.removeItem(STORAGE_TOKEN_KEY)
            history.push('/login')
            setLoading(false)
            isApiSubscribed = false
          }
        }
      })
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [medicalID, url, setRecoil, history, setLoading])
}

import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import CheckBox from '../components/Checkbox'
import PropTypes from 'prop-types'

TableRelationship.propTypes = {
  data: PropTypes.object,
}

export default function TableRelationship({ data }) {
  const borderColor = '#d9da55'
  const styles = StyleSheet.create({
    tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      borderWidth: 1,
      borderColor: '#d9da55',
    },
    titleContainer: {
      flexDirection: 'row',
      borderBottomColor: '#d9da55',
      backgroundColor: '#afbc21',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 24,
      textAlign: 'center',
      fontStyle: 'bold',
      flexGrow: 1,
    },
    title: {
      color: '#015287',
      fontWeight: 'bold',
      height: '100%',
      paddingTop: 8,
    },
    borderRight: {
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    rowContainer: {
      flexDirection: 'row',
      borderBottomColor: '#d9da55',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 24,
      fontStyle: 'bold',
    },
    row: {
      textAlign: 'center',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
      color: '#015287',
      backgroundColor: '#f9f9ea',
      height: '100%',
    },
    box: {
      width: 10,
      height: 10,
      border: '1px solid black',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 auto',
      fontSize: 7,
    },
  })

  const border = (data) => (data.border ? styles.borderRight : null)

  const CheckboxNoText = (isCheck) => {
    return <View style={styles.box}>{isCheck.isCheck ? <Text>x</Text> : null}</View>
  }

  const headers = [
    {
      title: '',
      width: '20%',
      border: true,
    },
    {
      title: 'N/A:',
      width: '10%',
      border: true,
    },

    {
      title: 'Poorly',
      width: '7%',
      border: false,
    },
    {
      title: '',
      width: '7%',
      border: false,
    },
    {
      title: '',
      width: '7%',
      border: false,
    },
    {
      title: '',
      width: '7%',
      border: false,
    },
    {
      title: 'Fine',
      width: '7%',
      border: false,
    },
    {
      title: '',
      width: '7%',
      border: false,
    },
    {
      title: '',
      width: '7%',
      border: false,
    },
    {
      title: '',
      width: '7%',
      border: false,
    },
    {
      title: 'Very Well',
      width: '14%',
      border: false,
    },
  ]

  const items = JSON.parse(data.answer).map((el) => {
    return {
      title: { data: { type: 'text', text: el.relation }, width: '20%', border: true },
      na: { data: { type: 'checkbox1', check: el.na }, width: '10%', border: true },
      c1: {
        data: { type: 'checkbox', check: Number(el.scale) === 1 ? true : false },
        width: '7%',
        border: true,
      },
      c2: {
        data: { type: 'checkbox', check: Number(el.scale) === 2 ? true : false },
        width: '7%',
        border: true,
      },
      c3: {
        data: { type: 'checkbox', check: Number(el.scale) === 3 ? true : false },
        width: '7%',
        border: true,
      },
      c4: {
        data: { type: 'checkbox', check: Number(el.scale) === 4 ? true : false },
        width: '7%',
        border: true,
      },
      c5: {
        data: { type: 'checkbox', check: Number(el.scale) === 5 ? true : false },
        width: '7%',
        border: true,
      },
      c6: {
        data: { type: 'checkbox', check: Number(el.scale) === 6 ? true : false },
        width: '7%',
        border: true,
      },
      c7: {
        data: { type: 'checkbox', check: Number(el.scale) === 7 ? true : false },
        width: '7%',
        border: true,
      },
      c8: {
        data: { type: 'checkbox', check: Number(el.scale) === 8 ? true : false },
        width: '7%',
        border: true,
      },
      c9: {
        data: { type: 'checkbox', check: Number(el.scale) === 9 ? true : false },
        width: '7%',
        border: true,
      },
      c10: {
        data: { type: 'checkbox', check: Number(el.scale) === 10 ? true : false },
        width: '7%',
        border: false,
      },
    }
  })

  return (
    <View style={styles.tableContainer}>
      {/* headers */}
      <View style={styles.titleContainer}>
        {headers.map((head, i) => (
          <Text key={i} style={{ ...styles.title, ...border(head), width: head.width }}>
            {head.title}
          </Text>
        ))}
      </View>
      {/* rows */}
      {items.map((data, j) => {
        const keys = Object.keys(data)
        return (
          <View style={styles.rowContainer} key={j}>
            {keys.map((el, k) => (
              <View key={k} style={{ ...styles.row, width: data[el].width, ...border(data[el]) }}>
                {data[el].data.type === 'text' ? (
                  <Text>{data[el].data.text}</Text>
                ) : data[el].data.type === 'checkbox' ? (
                  <CheckBox text={el.slice(1)} isCheck={data[el].data.check} />
                ) : data[el].data.type === 'checkbox1' ? (
                  <CheckboxNoText isCheck={data[el].data.check} />
                ) : null}
              </View>
            ))}
          </View>
        )
      })}
    </View>
  )
}

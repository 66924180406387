import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useRecoilState } from 'recoil'
import { STORAGE_TOKEN_KEY } from '../api/constant'
import { dataDoctorState, fetchDataDoctor } from '../store'

export default function useFetchDataDoctorList(setLoading) {
  const [dataDoctorList, setDataDoctorList] = useRecoilState(dataDoctorState)

  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      if (!dataDoctorList.length) {
        setLoading(true)
        fetchDataDoctor((data, err) => {
          if (data) {
            setDataDoctorList(data)
            setLoading(false)
            if (!data.length) {
              isApiSubscribed = false
            }
          } else {
            if (err.redirect) {
              localStorage.removeItem(STORAGE_TOKEN_KEY)
              history.push('/login')
              setLoading(false)
            }
          }
        })
      }
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [dataDoctorList.length, setDataDoctorList, setLoading, history])

  return { dataDoctorList }
}

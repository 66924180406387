import React from 'react'
import PropTypes from 'prop-types'
import { CModal, CModalBody } from '@coreui/react'

ModalViewAttachment.propTypes = {
  isModal: PropTypes.bool,
  setIsModal: PropTypes.func,
  url: PropTypes.string,
  setUrl: PropTypes.func,
}

export default function ModalViewAttachment({ isModal, setIsModal, url, setUrl = null }) {
  return (
    <CModal
      className="d-flex justify-content-center align-items-center"
      visible={isModal}
      onClose={() => {
        setIsModal(false)

        if (setUrl) {
          setUrl('')
        }
      }}
    >
      <CModalBody>
        <img alt="" src={url} style={{ width: 450, height: 450, objectFit: 'cover' }} />
      </CModalBody>
    </CModal>
  )
}

import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch role
export const dataRoleState = atom({
  key: 'dataRoleState',
  default: { meta: {}, rows: [] },
})

export const dataRoleListState = atom({
  key: 'dataRoleListState',
  default: { meta: {}, rows: [] },
})

export const fetchDataRole = async (params = '?', cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`roles${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataRoleList = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`roles/list`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch permission
export const dataPermissionState = atom({
  key: 'dataPermissionState',
  default: [],
})
export const fetchDataPermission = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('roles/permissions')

    if (data.status) {
      cb(data.data.permissions)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// create role
export const createRole = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('roles', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// delete role
export const deleteRole = async (param, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete('roles/' + param)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch one role
export const dataRoleOneState = atom({
  key: 'dataRoleOneState',
  default: {},
})
export const fetchDataRoleOne = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('roles/' + params + '?permission_code=true')

    if (data.status) {
      cb(data.data.role)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// update role
export const updateDataRoleOne = async (params, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch('roles/' + params, payload)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { STORAGE_TOKEN_KEY } from '../api/constant'
import { fetchDataAppointmentTreatmentList } from '../store'

export default function useFetchDataTreatmentAllList(setLoading) {
  const [treatmentList, setTreatmentList] = useState([])

  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      fetchDataAppointmentTreatmentList('', '', (data, err) => {
        if (data) {
          setTreatmentList(data)
          if (!data.length) {
            isApiSubscribed = false
          }
        } else {
          if (err.redirect) {
            localStorage.removeItem(STORAGE_TOKEN_KEY)
            history.push('/login')
          }
        }
      })
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading, history])

  return { treatmentList }
}

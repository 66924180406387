import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch feedbacks
export const dataVitalSignState = atom({
  key: 'dataVitalSignState',
  default: { rows: [], meta: {} },
})
export const fetchDataVitalSignNew = async (id, params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`vitalsigns/${id}?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// create vitalsign
export const createVitalSign = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('vitalsigns', input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchTodayVitalSign = async (medicalId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`vitalsigns/today-data/${medicalId}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

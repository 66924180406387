import { CFormInput } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useDebounce from '../../hooks/useDebounce'

LiveSearchContentCMS.propTypes = {
  setData: PropTypes.func,
  param: PropTypes.string,
  fetchData: PropTypes.func,
  placeholder: PropTypes.string,
  contentType: PropTypes.string,
}

export default function LiveSearchContentCMS({
  setData,
  param,
  fetchData,
  placeholder,
  contentType,
}) {
  const [search, setSearch] = useState('')
  const [isSearch, setIsearch] = useState(false)

  const searchValue = useDebounce(search)

  useEffect(() => {
    if (isSearch) {
      const params = `${param}=${searchValue}`
      fetchData(contentType, params, (data, err) => {
        setData(data)
      })
    }
  }, [searchValue, setData, param, fetchData, isSearch, contentType])

  return (
    <>
      <CFormInput
        placeholder={placeholder}
        onChange={(e) => {
          setSearch(e.target.value)
          setIsearch(true)
        }}
      />
    </>
  )
}

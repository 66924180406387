import { atom } from 'recoil'
import { API } from '../api/helpers'

// fetch data active patient count
export const dataDashboardPatientActiveCount = atom({
  key: 'dataDashboardPatientActiveCount',
  default: null,
})

export const fetchDataDashboardPatientActiveCount = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary/count-active`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch data inactive patient count
export const dataDashboardPatientInactiveCount = atom({
  key: 'dataDashboardPatientInactiveCount',
  default: null,
})

export const fetchDataDashboardPatientInactiveCount = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary/count-inactive`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch data new patient count
export const dataDashboardPatientNewCount = atom({
  key: 'dataDashboardPatientNewCount',
  default: null,
})

export const fetchDataDashboardPatientNewCount = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary/count-new`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch data appointment today
export const dataDashboardAppointmentToday = atom({
  key: 'dataDashboardAppointmentToday',
  default: { rows: [], meta: {} },
})

export const fetchDataDashboardAppointmentToday = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`appointments?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch data birthday today
export const dataDashboardBirthdayToday = atom({
  key: 'dataDashboardBirthdayToday',
  default: { rows: [], meta: {} },
})

export const fetchDataDashboardBirthdayToday = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/birthday?size=4&${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch data summary
export const dataDashboardSummary = atom({
  key: 'dataDashboardSummary',
  default: {},
})

export const fetchDataDashboardSummary = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`feedback/summary-rate?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' click ok to refresh website')
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

import { atom } from 'recoil'
import { API } from '../api/helpers'

export const dataUserLoggedInState = atom({
  key: 'dataUserLoggedInState',
  default: {},
})
export const fetchDataUserLoggedIn = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('auth/user')

    if (data.status) {
      cb(data.data.user)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' harap refresh website')
      }
    } else {
      alert(error.message)
    }
  }
}

export const isRefetchingState = atom({
  key: 'isRefetchingState',
  default: false,
})

import React, { Fragment } from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import PropTypes from 'prop-types'

const borderColor = '#d9da55'
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: '#d9da55',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
  },
  row: {
    textAlign: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 8,
    color: '#015287',
    backgroundColor: '#f9f9ea',
    height: '100%',
  },
  borderRight: {
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
})

const border = (data) => (data.border ? styles.borderRight : null)

const TableRow = ({ items }) => {
  const rows = items.map((data, i) => {
    const keys = Object.keys(data)
    return (
      <View style={styles.container} key={i}>
        {keys.map((el, j) => (
          <Text key={j} style={{ ...styles.row, width: data[el].width, ...border(data[el]) }}>
            {data[el].data}
          </Text>
        ))}
      </View>
    )
  })
  return <Fragment>{rows}</Fragment>
}

TableRow.propTypes = {
  items: PropTypes.array,
}

export default TableRow

import { useEffect } from 'react'
import { useHistory } from 'react-router'

import { STORAGE_TOKEN_KEY } from '../api/constant'

export default function useFetchNextAppointmet(
  fetchData,
  params,
  data,
  setData,
  setLoading,
  medicalId,
) {
  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      setLoading(true)
      fetchData(`${params}&medicalId=${medicalId}`, (_data, err) => {
        if (_data) {
          setData(_data)
          setLoading(false)
          isApiSubscribed = false
        } else {
          if (err) {
            if (err.redirect) {
              localStorage.removeItem(STORAGE_TOKEN_KEY)
              history.push('/login')
              setLoading(false)
            }
          }
        }
      })
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicalId, history])

  return { data, setData }
}

import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch vouchers
export const allVoucherState = atom({
  key: 'allVoucherState',
  default: { rows: [], meta: {} },
})
export const fetchAllVoucher = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`vouchers?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// voucher types
export const voucherTypeState = atom({
  key: 'voucherTypeState',
  default: [],
})
export const fetchVoucherTypes = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`voucher-types/list`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// create voucher
export const createVoucherType = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('vouchers', input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch one voucher
export const voucherOneState = atom({
  key: 'voucherOneState',
  default: {},
})
export const fetchDataVoucherOne = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`vouchers/${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// update voucher
export const updateVoucher = async (voucherId, input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`vouchers/${voucherId}`, input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// delete voucher
export const deleteVoucher = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete(`vouchers/${id}`)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// share voucher
export const shareVoucher = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`vouchers/share`, input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const voucherHistoryState = atom({
  key: 'voucherHistoryState',
  default: { rows: [], meta: {} },
})
export const fetchVoucherHistory = async (voucherCode, params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`vouchers/share/${voucherCode}?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const downloadVoucher = async (voucherCode, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`vouchers/download/${voucherCode}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

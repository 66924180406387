import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

export const dataCorporateList = atom({
  key: 'dataCorporateList',
  default: [],
})

export const fetchDataCorporateList = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`corporates/list`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchARCorporate = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`corporates?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const createPayerCorporate = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`corporates`, params)
    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const getPayerCorporateOne = async (code, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`corporates/${code}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const updatePayerCorporate = async (code, params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`corporates/${code}`, params)
    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const deactivateCorporate = async (code, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete(`corporates/${code}`)
    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
export const activateCorporate = async (code, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`corporates/restore/${code}`)
    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

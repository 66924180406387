import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch setting
export const settingsPaginationState = atom({
  key: 'settingsPaginationState',
  default: { rows: [], meta: {} },
})
export const fetchSettingsPagination = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`settings?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch one setting
export const fetchSettingOne = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`settings/${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

//update setting
export const updateSetting = async (id, input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`settings/${id}`, input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const getSettingSelectDateTrx = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('settings/toggle-feature/SD_TRX')
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const setSettingSelectDateTrx = async (payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('settings/toggle-feature', payload)
    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const toogleDateTrxHistoryState = atom({
  key: 'toogleDateTrxHistoryState',
  default: { rows: [], meta: {} },
})

export const fetchDateTrxHistoryPagination = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`settings/toggle-feature-history/SD_TRX?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

import { useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  dataDoctorState,
  dataEducationState, //
  dataIdentityTypeState, //
  dataMaritalStatusState, //
  dataOccupationState, //
  dataReligionState,
  dataSpecialistState,
  fetchDataDoctor, //
  fetchDataEducation,
  fetchDataIdentityType,
  fetchDataMaritalStatus,
  fetchDataOccupation,
  fetchDataReligion,
  fetchDataSpecialist,
} from '../store'
import { useRecoilState } from 'recoil'

export default function useFetchDataGeneralSave(setLoading) {
  useFetchDataGeneralSave.propTypes = {
    setLoading: PropTypes.func,
  }
  const [dataDoctor, setDataDoctor] = useRecoilState(dataDoctorState)
  const [dataEducation, setDataEducation] = useRecoilState(dataEducationState)
  const [dataIdentityType, setDataIdentityType] = useRecoilState(dataIdentityTypeState)
  const [dataMaritalStatus, setDataMaritalStatus] = useRecoilState(dataMaritalStatusState)
  const [dataOccupation, setDataOccupation] = useRecoilState(dataOccupationState)
  const [dataReligion, setDataReligion] = useRecoilState(dataReligionState)
  const [dataSpecialist, setDataSpecialist] = useRecoilState(dataSpecialistState)

  useEffect(() => {
    let isApiSubscribed = true

    async function getGeneralData() {
      setLoading(true)

      await fetchDataDoctor((doc) => {
        setDataDoctor(doc)
      })

      await fetchDataEducation((edu) => {
        setDataEducation(edu)
      })

      await fetchDataIdentityType((identity) => {
        setDataIdentityType(identity)
      })

      await fetchDataMaritalStatus((marital) => {
        setDataMaritalStatus(marital)
      })

      await fetchDataOccupation((occupation) => {
        setDataOccupation(occupation)
      })

      await fetchDataReligion((religion) => {
        setDataReligion(religion)
      })

      await fetchDataSpecialist((special) => {
        setDataSpecialist(special)
      })

      setLoading(false)
    }

    if (isApiSubscribed) {
      if (!dataEducation.length) {
        getGeneralData()
      }
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [
    dataEducation.length,
    setLoading,
    setDataDoctor,
    setDataEducation,
    setDataIdentityType,
    setDataMaritalStatus,
    setDataOccupation,
    setDataReligion,
    setDataSpecialist,
  ])

  return {
    dataDoctor,
    dataEducation,
    dataIdentityType,
    dataMaritalStatus,
    dataOccupation,
    dataReligion,
    dataSpecialist,
  }
}

import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch data survey active
export const dataSurveyActiveState = atom({
  key: 'dataSurveyActiveState',
  default: [],
})

export const fetchDataSurveyActive = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`surveygenerator/active`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch data survey all
export const dataSurveyState = atom({
  key: 'dataSurveyState',
  default: { rows: [], meta: {} },
})

export const fetchDataSurvey = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`surveygenerator?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// create survey
export const createSurvey = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('surveygenerator', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// delete question
export const deleteQuestion = async (param, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete('surveygenerator/' + param)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch one survey
export const dataSurveyOneState = atom({
  key: 'dataSurveyOneState',
  default: {},
})
export const fetchDataSurveyOne = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`surveygenerator/${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// update question
export const updateQuestion = async (questionId, input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`surveygenerator/${questionId}/update`, input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// get video attachment
export const videoAttachmentState = atom({
  key: 'videoAttachmentState',
  default: null,
})
export const fetchVideoAttachment = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/surveygenerator/get/videoattachment`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

import { useEffect } from 'react'
import { useHistory } from 'react-router'

import { STORAGE_TOKEN_KEY } from '../../api/constant'

export default function useFetchOne(fetchData, paramId, data, setData, setLoading, nested = false) {
  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      if (paramId) {
        setLoading(true)
        fetchData(paramId, (_data, err) => {
          if (nested) {
            setData(_data)
          } else {
            if (_data.status) {
              setData(_data.data)
              setLoading(false)
              isApiSubscribed = false
            } else {
              if (err) {
                if (err.redirect) {
                  localStorage.removeItem(STORAGE_TOKEN_KEY)
                  history.push('/login')
                  setLoading(false)
                }
              }
            }
          }
        })
      }
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [fetchData, history, paramId, setLoading, setData, nested])

  return { data, setData }
}
